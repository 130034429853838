.ct-series-a .ct-bar, .ct-series-a .ct-line, .ct-series-a .ct-point, .ct-series-a .ct-slice-donut {
    stroke: var(--color-light-blue);
}
.ct-series-b .ct-bar, .ct-series-b .ct-line, .ct-series-b .ct-point, .ct-series-b .ct-slice-donut {
    stroke: var(--color-primary);
}
.ct-labels, .ct-grids {
    display: none;
}
.ct-tooltip, .ct-tooltip:before {
    position: absolute;
    pointer-events: none;
}
.ct-tooltip {
    display: inline-block;
    min-width: calc(40rem/16);
    padding: calc(5rem/16);
    background: var(--color-dark);
    font-family: var(--font-default);
    color: var(--color-text-default);
    text-align: center;
    z-index: 100;
    transition: opacity .2s linear;
    font-size: calc(14rem/16);
    transform: translate(13%, 0px);
}
.ct-tooltip:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    border-top-color: var(--color-dark);
    border-width: 7px;
    transform: translateX(-50%);
}
.ct-tooltip:after {
    content:'°C';
}
.ct-point.ct-circle,  .ct-series-a .ct-point.ct-circle, .ct-series-b .ct-point.ct-circle {
    fill: var(--color-dark);
    stroke: var(--color-light);
    stroke-width: calc(2rem/16);
    box-shadow: 5px 5px 10px rgba(0,0,0,0.39);
}