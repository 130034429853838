.nav-tabs{
    border-bottom: 1px solid var(--color-light);
}
.nav-tabs:not(.nav-tabs-icon) .nav-link{
    position: relative;
    padding: calc(15rem/16) calc(15rem/16);
    color: var(--color-light);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(14rem/16);
    
    @media screen and (min-width: 1200px){
       font-size: calc(16rem/16);
    }
}
.nav-tabs.nav-tabs--left .nav-link {
    justify-content: flex-start;
}

.nav-tabs:not(.nav-tabs-icon) .nav-link:after{
    content: '';
    position: absolute;
    bottom: calc(-1rem/16);
    left: calc(-1rem/16);
    right: calc(-1rem/16);
    height: calc(4rem/16);
    background-color: var(--color-primary);
    transform: translateY(calc(100% - 1px)) scaleY(0);
    transition: all 250ms ease;
    transform-origin: top;
}

.nav-tabs:not(.nav-tabs-icon) .nav-item.show .nav-link:after,
.nav-tabs:not(.nav-tabs-icon) .nav-link:hover:after,
.nav-tabs:not(.nav-tabs-icon) .nav-link.active:after{
    transform: translateY(0) scaleY(1);
    height: calc(4rem/16);

}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active{
    color: var(--color-text-default);
}
