.event-teaser__embed--portrait {
    padding-top: calc(579 / 372 * 100%);
    @media screen and (max-width: 767px) {
        padding-top: 100%;
    }
}
.event-teaser__embed--landscape {
    padding-top: calc(579 / 783 * 100%);
    @media screen and (max-width: 767px) {
        padding-top: 100%;
    }
}

.event-teaser {
    position: relative;
    cursor:pointer;
}
.event-teaser:before {
    opacity: 0.5;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    content:'';
    transition: opacity 0.4s cubic-bezier(0.7, 0, 0.3, 1);
    background: linear-gradient(180deg, rgba(61,61,61,0) 0%, #3D3D3D 100%);
}
.event-teaser__content {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    text-shadow: 0 calc(2rem/16) calc(4rem/16) rgba(0,0,0,0.5);
    flex-direction: column;
    justify-content: space-between;
    padding: calc(30rem/16) calc(30rem/16) calc(50rem/16);
    color: var(--color-white);
    @media screen and (max-width: 1199px) and (min-width: 768px) {
        padding: calc(10rem/16);
    }
}

.event-teaser--profile .event-teaser__content {
    @media screen and (max-width:767px){
        padding: calc(15rem/16);
    }
}

.event-teaser__date {
    font-family: var(--font-default-bold);
}
.event-teaser__location-icon {
    font-size: calc(24rem/16);
}
.event-teaser__location {
    display: flex;
    align-items: center;
    margin-bottom: calc(10rem/16);
}
.event-teaser__title {
    font-size: calc(24rem/16);
    font-family: var(--font-default-bold);
    line-height: 1;
    text-transform: uppercase;
    word-break: break-word;
}
.event-teaser__sub-title {
    text-transform: uppercase;
    margin-top: calc(5rem/16);
}
.event-teaser__calendar-icon {
    font-size: calc(24rem/16);
}
@media screen and (min-width: 768px) {
    .event-teaser__media {
        transition: transform 0.4s cubic-bezier(0.7, 0, 0.4, 1);
    }
    .event-teaser:hover .event-teaser__media {
        transform: scale(1.1);
    }
    .event-teaser:hover:before {
        opacity:0.3;
    }
}