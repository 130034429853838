.icon-teaser{
    position: relative;
    overflow: hidden;
    background-color: var(--color-dark);
    @media screen and (min-width: 768px) {
        height: 100%;
    }
    @media screen and (min-width: 1400px){
       min-height: calc(385rem/16);
    }
}
.icon-teaser__img {
    transform: rotate(-10deg);
}
.icon-teaser:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 270px 270px 0 0;
    border-color: var(--color-dark-highlight) transparent transparent transparent;

    @media screen and (min-width: 1400px){
        border-width: 385px 496px 0 0;
    }
    @media screen and (min-width: 1100px){
        border-width: 258px 402px 0 0;

    }
}

.icon-teaser__body{
    padding: calc(30rem/16);
    color: var(--color-text-default);
    position: relative;
    z-index: 1;
    
    @media screen and (min-width: 1200px){
       padding: 12% 7%;
    }
    
    @media screen and (min-width: 768px){
        padding: calc(25rem/16) calc(20rem/16);

        height: 100%;
        display: flex;
        flex-direction: column;
    }
}

.icon-teaser__icon{
    color: var(--color-light-blue);
    font-size: calc(45rem/16);


    @media screen and (min-width: 1400px){
        font-size: calc(55rem/16);
    }

    @media screen and (min-width: 768px) and (max-width: 1400px){
        font-size: calc(40rem/16);
    }
}

.icon-teaser__title{
    line-height: calc(24/20);
    font-family: var(--font-headline);
    text-transform: uppercase;
    margin-top: calc(30rem/16);
    font-size: calc(18rem/16);


    @media screen and (min-width: 768px){
        margin-top: calc(20rem/16);
        font-size: calc(16rem/16);
    }

    @media screen and (min-width: 1400px){
        margin-top: calc(50rem/16);
        font-size: calc(20rem/16);
    }
}

.icon-teaser__text{
    margin-top: calc(30rem/16);
    font-family: var(--font-default);
    @media screen and (min-width: 768px) and (max-width: 1400px){
        font-size: 14px;
        margin-top: calc(20rem/16);
    }
}
.icon-teaser__text.wysiwyg a {
    color: currentColor;
    text-decoration: underline;
}

/* icon teaser download */
.icon-teaser-download .icon-teaser__icon {
    @media screen and (min-width: 1400px){
        font-size: calc(105rem/16);
        margin-top: calc(100rem/16);
    }
}
.icon-teaser-download .icon-teaser__title {
    text-align: left;
    @media screen and (min-width: 768px) {
        margin-top: calc(100rem / 16);
    }
}

/* profile icon teaser */

.profile-icon-teaser {
    position: relative;
    overflow: hidden;
    background-color: var(--color-dark);
    min-height: calc(260rem/16);
}
.profile-icon-teaser:hover::before {
    border-color: var(--color-dark-highlight--light) transparent transparent transparent;
}

.profile-icon-teaser::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 300px 320px 0 0;
    transition: border-color .35s ease-in-out;
    border-color: var(--color-dark-highlight) transparent transparent transparent;
}

.profile-icon-teaser__content{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 2;
    min-height: calc(260rem/16);
    width: 100%;
    padding: 1rem;
    text-align: center;
}
.profile-icon-teaser__icon {
    color: var(--color-primary);
    font-size: calc(70rem / 16);
    margin-bottom: calc(24rem /16);
}
.profile-icon-teaser__link {
    color: #fff;
    text-transform: uppercase;
    z-index: 2;
    font-size: calc(20rem / 16);
    font-family: var(--font-default-bold);
}