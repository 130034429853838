.custom-checkbox {
    position: relative;
    padding-left: 1.7em;
}

.custom-checkbox:before,
.custom-checkbox:after {
    content: " ";
    display: table;
}

.custom-checkbox:after {
    clear: both;
}

.custom-checkbox > label {
    cursor: pointer;
}

.custom-checkbox__input {
    position: absolute;
    width: calc(1rem / 16);
    height: calc(1rem / 16);
    margin: calc(-1rem / 16);
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.custom-checkbox__box {
    position: absolute;
    left: 0;
    margin-right: calc(7rem / 16);
    overflow: hidden;
    border: 1px solid var(--color-form-elements);
    background: transparent;
    width: 1.25em;
    height: 1.25em;
    margin-top: 0.12em;
}

.custom-checkbox__box:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 0.5em;
    color: var(--color-form-elements);
    visibility: hidden;
}

.custom-checkbox__input:checked ~ .custom-checkbox__box:before {
    visibility: visible;
}

.custom-checkbox__input:focus ~ .custom-checkbox__box {
    border-color: var(--color-form-elements);
}

.has-error .custom-checkbox__box {
    border-color: var(--color-danger)
}

.custom-checkbox__text {
    color: var(--color-form-elements);
    display: block;
    overflow: hidden;
}

.custom-checkbox__text a {
    color: var(--color-text-default);
    text-decoration: underline;
    transition: color 0.2s ease-in;
}

.custom-checkbox__text a:hover,
.custom-checkbox__text a:focus,
.custom-checkbox__text a:active {
    color: var(--color-primary);
    text-decoration: underline;
}

.checkbox--white .custom-checkbox__input:focus ~ .custom-checkbox__box,
.checkbox--white .custom-checkbox__box,
.checkbox--white .custom-checkbox__box:before {
    border-color: #fff;
    color: #fff;
}

.checkbox--white .custom-checkbox__text {
    color: #fff;
}
