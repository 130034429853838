.blog-author-intro {
    position: relative;
    padding-bottom: calc(40rem/16);
}
.blog-author-intro:before {
    content:'';
    position: absolute;
    z-index: -1;
    background: linear-gradient(to bottom, rgba(29,29,29,1) 0%,var(--color-secondary) 100%);
    top:0;
    left:0;
    width: 100%;
    height: 100%;
}
.blog-author-intro__author-img {
    padding-top: 6%;
}
.blog-author-intro__title {
    text-transform: uppercase;
}
.blog-author-intro__subtitle {
    font-size: calc(26rem/16);
    margin-top: calc(10rem/16);
}
.blog-author-intro__body {
    text-align: center;
    padding-top: calc(40rem/16);
}