.product-thumb-slider .slick-list.draggable {
    overflow: visible;
}

.product-thumb-slider {
    margin-top: calc(25rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(35rem / 16);
    }
}

.product-thumb-slider__thumbs .slick-slide {
    transform: scale(1);
    transition: transform 0.2s;
}

.product-thumb-slider__thumbs .slick-slide.slick-current {
    transform: none;

    @media screen and (min-width: 768px) {
        transform: scale(1.1);
    }
}

.product-thumb-slider__thumbs {
    margin: 0 calc(-5rem / 16);

    @media screen and (min-width: 768px) {
        padding: 0 calc(-10rem / 16);
    }

    @media screen and (min-width: 1200px) {
        padding: 0 calc(-19rem / 16);
    }
}

.product-thumb-slider__item {
    max-width: calc(1200rem / 16);
    margin: calc(32rem / 16) auto 0;

    @media screen and (min-width: 768px) {
        margin: calc(50rem / 16) auto 0;
    }

    @media screen and (min-width: 1200px) {
        margin: calc(95rem / 16) auto 0;
    }
}

.product-thumb-slider__title {
    font-size: calc(16rem / 16);
    line-height: calc(17rem / 16);
    margin-bottom: calc(15rem / 16);
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    text-align: left;

    @media screen and (min-width: 768px) {
        font-size: calc(20rem / 16);
        line-height: calc(25rem / 16);
        margin-bottom: calc(20rem / 16);
        text-align: center;
    }

    @media screen and (min-width: 1200px) {
        font-size: calc(25rem / 16);
        line-height: calc(30rem / 16);
    }
}

.product-thumb-slider__img-title {
    font-size: calc(14rem / 16);
    line-height: calc(15rem / 16);
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    text-shadow: 0 calc(2rem / 16) calc(5rem / 16) rgba(0, 0, 0, 0.6);
    position: absolute;
    top: calc(2rem / 16);
    left: calc(2rem / 16);
    right: calc(2rem / 16);
    z-index: 2;
    word-break: break-word;

    @media screen and (min-width: 768px) {
        text-shadow: 0 calc(2rem / 16) calc(4rem / 16) rgba(0, 0, 0, 0.5);
        transform: none;
        text-align: left;
        font-size: calc(16rem / 16);
        line-height: calc(19rem / 16);
        bottom: auto;
        top: calc(12rem / 16);
        left: calc(12rem / 16);
        right: calc(12rem / 16);
        width: auto;
    }

    @media screen and (min-width: 1200px) {
        font-size: calc(20rem / 16);
        line-height: calc(23rem / 16);
        top: calc(15rem / 16);
        left: calc(15rem / 16);
        right: calc(15rem / 16);
    }

    @media screen and (min-width: 1400px) {
        font-size: calc(25rem / 16);
        line-height: calc(26rem / 16);
        top: calc(30rem / 16);
        left: calc(30rem / 16);
        right: calc(30rem / 16);
    }
}

.product-thumb-slider__thumbs .slick-slide {
    padding: 0 calc(5rem / 16);

    @media screen and (min-width: 768px) {
        padding: 0 calc(10rem / 16);
    }

    @media screen and (min-width: 1200px) {
        padding: 0 calc(19rem / 16);
    }
}

.product-thumb-slider__main .slick-slide {
    height: auto;
}

.product-thumb-slider__thumb-item::after {
    content: '';
    z-index: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    transition: all 0.3s;

    @media screen and (min-width: 768px) {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
        bottom: 50%;
    }
}

.slick-current .product-thumb-slider__thumb-item::after {
    @media screen and (max-width: 767px) {
        background: rgba(0, 0, 0, 0.3);
    }
}

.product-thumb-slider__main .slick-slide {
    transition: all 0.6s !important;
}

.product-thumb-slider__text-thumbs {
    font-family: var(--font-default-bold);
    font-size: calc(14rem / 16);
    line-height: 1.2;
    text-transform: uppercase;
    text-align: center;
    border: 1px solid #FFF;
    padding: calc(12rem / 16) calc(5rem / 16);
    word-break: break-word;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.slick-current .product-thumb-slider__text-thumbs {
    background-color: #FFF;
    color: var(--color-text-default-dark);
}

.product-thumb-slider__thumbs .slick-track {
    display: flex !important;
}

.product-thumb-slider__thumbs .slick-slide {
    height: inherit !important;
}

.product-thumb-slider__thumbs .slick-slide > div {
    height: 100%;
}