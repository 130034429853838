@media screen and (max-width: 767px) {
    .navbar-console {
        position: fixed;
        bottom:0;
        width: 100%;
        background-color: var(--color-secondary);
        height: calc(80rem/16);
        z-index: 999;
    }
    .navbar-console__nav {
        padding-left: calc(19rem/16);
        padding-right: calc(19rem/16);
    }
    .navbar-console__icon {
        font-size: calc(30rem/16);
    }
    .nav-open .navbar-console {
        z-index: 99;
    }
    .navbar-console__tosc {
        position: absolute;
        font-size: 21.4px;
        right: 40px;
        top: 15.2px;
    }
    .navbar-console__item-text{
        font-size: 11px;
        margin-top: calc(6rem/16);
        padding-right: calc(4rem/16);
        padding-left: calc(4rem/16);
    }
}