.video-area__video {
    box-shadow: 0 calc(50rem/16) calc(60rem/16) 0 rgba(0,0,0,0.2);
    position: relative;
}
.video-area__video-info {
    background-color: #fff;
    opacity: 0.9;
    box-shadow: 0 calc(2rem/16) calc(20rem/16) 0 rgba(0,0,0,0.5);
    position: absolute;
    right: calc(20rem/16);
    bottom: calc(20rem/16);
    padding: calc(10rem/16);
    color: var(--color-dark-grey);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    line-height: 1;
    @media screen and (max-width: 767px) {
        right: calc(5rem/16);
        bottom: calc(5rem/16);
        padding: calc(5rem/16);
    }
}
.video-area__video-info__icon {
    font-size: calc(24rem/16);
    padding-right: calc(10rem/16);
}
.vjs-has-started + .video-area__video-info {
    display: none !important;
}