.tour-data-list .list-inline-item:not(:last-child){

    @media screen and (min-width: 768px){
        margin-right: calc(40rem/16);
    }
}

.tour-data__value{
    font-family: var(--font-default-bold);
    line-height: calc(44/36);
    display: flex;
    align-items: center;
    font-size: calc(14rem/16);

    @media screen and (min-width: 1100px) {
        font-size: responsive 28px 36px;
        font-range: 1100px 1400px;
    }
    
    @media screen and (min-width: 768px){
       font-size: calc(25rem/16);
    }
}

.tour-data__value .icon{
    font-size: calc(16rem/16);
    margin-right: calc(10rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(19rem/16);
        margin-right: calc(15rem/16);
    }
}