html {
    /* Table scaling */
    @media screen and (min-width: 768px) and (max-width: 991px) {
        font-size: calc(16 / (991 / 100) * 1vw);
    }
}
body {
    color: var(--color-text-default);
    font-family: var(--font-default);
    font-size: var(--font-size-default);
    @media screen and (max-width: 767px) {
        font-size: var(--font-size-default--mobile);
    }
}
.font-default {
    font-family: var(--font-default);
    font-weight: var(--font-default-weight);
}

.font-bold{
    font-family: var(--font-default-bold);
}
.font-decorative{
    font-family: var(--font-decorative);
}

b,
strong,
.strong {
    font-family: var(--font-default-bold);
    font-weight: normal;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 0;
    display: block;
    color: inherit;
    hyphens: auto;
    /* break words only if the word has at least 20 characters */
    -webkit-hyphenate-limit-before: 10;
    -webkit-hyphenate-limit-after: 10;
    -moz-hyphenate-limit-chars: 20 3 2;
    -webkit-hyphenate-limit-chars: 20 3 2;
    -ms-hyphenate-limit-chars: 20 3 2;
    hyphenate-limit-chars: 20 3 2;
}

h1, .h1 {
    font-size: calc(22rem/16);
    font-family: var(--font-headline);
    @media screen and (min-width: 768px) {
        font-size: responsive 40px 50px;
        font-range: 768px 1400px;
    }

}
h2, .h2 {
    font-size: calc(20rem/16);
    line-height: calc(50/40);
    font-family: var(--font-headline);
    @media screen and (min-width: 768px) {
        font-size: responsive 30px 40px;
        font-range: 768px 1400px;
    }
}
h3, .h3 {
    font-size: calc(18rem/16);
    line-height: calc(43/35);
    @media screen and (min-width: 768px) {
        font-size: responsive 25px 35px;
        font-range: 768px 1400px;
    }
}
h4, .h4 {
    font-size: calc(20rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(16rem/16);
    }
}
h5, .h5 {
    font-size: calc(18rem/16);
}
h6, .h6 {
    font-size: 1em;
}
table{
    word-break: initial;
}
