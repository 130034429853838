.card-teaser-slider  {
    max-width: calc(600rem/16);
    margin: 0 auto;
    @media screen and (max-width: 767px) {
        max-width: 95%
    }
}
.card-teaser-slider__item {
    max-width: calc(510rem/16);
    margin: 0 auto;
    @media screen and (max-width: 767px) {
        max-width: 85%
    }
}
.card-teaser-slider .slick-list {
    overflow: visible;
}
.card-teaser-slider .slick-slide {
    opacity:1 !important;
}
.card-teaser-slider__item {
    transform: rotate(20deg) scale(.8) translateX(300%) translateZ(50px);
    transform-origin: right;
    opacity: 0;
    transition: transform 0.3s ease, opacity 0.5s 0.1s ease;
    position:relative;
}
.card-teaser-slider .slick-current + .slick-slide .card-teaser-slider__item {
    opacity:1;
    transform-origin: center;
    transform: rotate(-2deg) translateX(-3.375rem) scale(0.94);
    transition: transform 0.3s ease, opacity 0.1s ease;
    @media screen and (max-width: 767px) {
        transform: rotate(-2deg) translateX(-1.375rem) scale(0.94);
    }
}
.card-teaser-slider .slick-current + .slick-slide + .slick-slide .card-teaser-slider__item {
    opacity:1;
    transform-origin: center;
    transform: rotate(2deg) translateX(3.375rem) scale(0.94);
    @media screen and (max-width: 767px) {
        transform: rotate(2deg) translateX(1.375rem) scale(0.94);
    }
}
.card-teaser-slider .slick-current .card-teaser-slider__item {
    opacity:1;
    transform-origin: center;
    transform: rotate(0deg) translateX(0) translateZ(150px);;
    transition: transform 0.3s ease, opacity 0.1s ease;
}
