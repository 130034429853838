.drag-teaser-slider__wrapper .slick-list {
    overflow: visible;
}
.drag-teaser-slider__wrapper {
    position: relative;
    padding-top: calc(30rem/16);
    padding-bottom: calc(30rem/16);
    cursor: none;

}
.drag-teaser-slider {
    margin-left: calc(-30rem/16);
    margin-right: calc(-30rem/16);
    @media screen and (max-width: 767px) {
        margin-left: calc(10rem/16);
        margin-right: calc(-10rem/16);
    }
}
.drag-teaser-slider__drag-indicator {
    height: calc(110rem/16);
    position: absolute;
    top:50%;
    display: grid;
    transform: translateY(-50%);
    font-size: calc(40rem/16);
    grid-template-columns: minmax(20px,1fr) auto minmax(20px,1fr);
    grid-gap: 1.625rem;
    width: 100%;
    left:0;
    right:0;
    pointer-events: none;
}
.drag-teaser-slider__drag-indicator:after, .drag-teaser-slider__drag-indicator:before {
    content: "";
    background-image: linear-gradient(to right, #fff 10%, rgba(148, 0, 16, 0) 0%);
    background-position: center;
    background-size: calc(10rem/16) calc(1rem/16);
    background-repeat: repeat-x;
}
.drag-teaser-slider__drag-indicator__icon {
    display: flex;
    align-items:center;
}
.drag-teaser-slider__item {
    padding-left: calc(30rem/16);
    padding-right: calc(30rem/16);
    margin-bottom: 1.625rem;
    @media screen and (max-width: 767px) {
        padding-left: calc(10rem/16);
        padding-right: calc(10rem/16);
    }
}
.drag-teaser-slider .slick-slide > div:nth-of-type(2) {
    transform: translate(10%);
    @media screen and (max-width: 767px) {
        transform: translate(20%);
    }
}
.drag-teaser-slider {
    padding-right: 10%;
    @media screen and (max-width: 767px) {
        padding-right: 20%;
    }
}
.drag-teaser-slider .slick-slide > div:nth-of-type(1) .drag-teaser-slider__item {
    margin-bottom: calc(150rem/16);
    @media screen and (max-width: 767px) {
        margin-bottom: calc(50rem/16);
    }
}
