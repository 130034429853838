.webcam-teaser {
    position: relative;
    box-shadow: 0 calc(50rem/16) calc(60rem/16) rgba(0,0,0,0.2);
}
.webcam-teaser__body {
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(61,61,61,0.6) 100%);
    padding: calc(30rem/16);
    @media screen and (max-width: 1199px) {
        padding: calc(15rem/16);
    }
}
.webcam-teaser__title {
    font-size: calc(24rem/16);
    text-shadow: 0 calc(2rem/16) calc(4rem/16) rgba(0,0,0,0.8);
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    @media screen and (max-width: 1199px) {
        font-size: calc(16rem/16);
    }
}
.webcam-teaser__sub-title {
    font-size: 1rem;
    text-shadow: 0 calc(2rem/16) calc(4rem/16) rgba(0,0,0,0.8);
    font-family: var(--font-default-bold);
    @media screen and (max-width: 767px) {
        font-size: calc(14rem/16);
    }
}
.webcam-teaser__play-btn {
    position: absolute;
    top: 50%;
    left:50%;
    transform: translate(-50%, -50%);
    font-size: calc(100rem/16);
    pointer-events: none;
    @media screen and (max-width: 767px) {
        font-size: calc(30rem/16);
    }
}
.webcam-teaser__embed--iframe:before {
    padding-top: 61%;
}
.webcam-teaser__cover-media {
    object-fit: cover;
}