.wysiwyg-with-medium__media {
    overflow: hidden;
    position: relative;
    box-shadow: 0 calc(50rem / 16) calc(60rem / 16) 0 rgba(0, 0, 0, 0.2);
}
.wysiwyg-with-medium__zoom-btn {
    position: absolute;
    z-index: 9;
    width: calc(200rem / 16);
    height: calc(50rem / 16);
    right: 0;
    bottom: 0;
    background-color: transparent;
    box-shadow: none;
    border: none;
    background-size: cover;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding: calc(10rem / 16) calc(50rem / 16);
    flex-direction: column;
    color: #fff;
    font-size: calc(20rem / 16);
    pointer-events: none;
    @media screen and (min-width: 768px) {
        opacity: 0;
        transform: translate(50%, 100%);
        transition: transform 0.3s ease, opacity 0.3s ease;
    }
}
.wysiwyg-with-medium__zoom-btn:after {
    content:'';
    position: absolute;
    z-index: -1;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 calc(40rem/16) calc(50rem/16) calc(110rem/16);
    bottom: 0;
    right: calc(10rem/16);
    border-color: transparent transparent var(--color-primary) transparent;
}
.wysiwyg-with-medium--wysiwyg-left .wysiwyg-with-medium__content {
    @media screen and (min-width: 768px) {
        text-align: right;
    }
}
.wysiwyg-with-medium--wysiwyg-left .wysiwyg  ul li:before {
    @media screen and (min-width: 768px) {
        right: 0;
        left: initial;
    }
}
.wysiwyg-with-medium--wysiwyg-left .wysiwyg ul li {
    @media screen and (min-width: 768px) {
        padding-right: calc(20rem / 16);
        padding-left: 0;
    }
}
@media screen and (min-width: 768px) {
    .wysiwyg-with-medium__media:hover .wysiwyg-with-medium__zoom-btn {
        opacity: 1;
        transform: translate(0, 0);
    }
}