.product-shops__list {
    margin-top: calc(15rem / 16);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-right: calc(-20rem/16);

    @media screen and (min-width: 768px){
        margin-right: calc(-80rem/16);
        margin-top: calc(35rem / 16);
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    @media screen and (min-width: 1200px){
        margin-right: calc(-120rem/16);
        margin-top: calc(45rem / 16);
    }
}

.product-shops__item {
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    font-size: calc(16rem / 16);
    line-height: 17px;
    margin-right: calc(20rem/16);
    margin-top: calc(14rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(20rem / 16);
        line-height: 25px;
        margin-right: calc(80rem/16);
        margin-top: calc(18rem/16);
    }

    @media screen and (min-width: 1200px){
        font-size: calc(25rem / 16);
        line-height: 30px;
        margin-right: calc(120rem/16);
        margin-top: calc(20rem/16);
    }
}

.product-shops__item > a {
    text-decoration: underline;
}

.product-shops__item::before {
    content: "\2BC0";
    color: var(--color-primary);
    font-size: calc(16rem/16);
    margin-right: calc(10rem/16);
    line-height: 17px;

    @media screen and (min-width: 768px){
        font-size: calc(18rem/16);
        margin-right: calc(16rem/16);
        line-height: 19px;
    }

    @media screen and (min-width: 1200px){
        font-size: calc(20rem/16);
        margin-right: calc(20rem/16);
        line-height: 30px;
    }
}