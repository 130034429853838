.hotspots {
    position: relative;
    overflow: hidden;
}

.hotspots__wrapper{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.hotspots__hotspot{
    position: relative;
    background-color: transparent;
    border-radius: 50%;
    width: calc(40rem/16);
    height: calc(40rem/16);
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 100ms ease-out;
    color: #313131;
}
.hotspots__hotspot--big {
    width: calc(50rem/16);
    height: calc(50rem/16);
}
.hotspots__hotspot:hover {
    animation: pulse 2s infinite;
}

.hotspots__hotspot:before{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 50%;
    background-color: var(--color-primary);
    opacity: 0.5;
    transition: all 250ms ease;
}

.hotspots__hotspot.is-open:before,
.hotspots__hotspot:hover:before {
    opacity: 1;
}

.hotspots__hotspot-inner{
    width: calc(22rem/16);
    height: calc(22rem/16);
    font-size: calc(9rem/16);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 50%;
    z-index: 2;
    transition: all 0.3s ease;
}
.hotspots__hotspot--big .hotspots__hotspot-inner {
    width: calc(28rem/16);
    height: calc(28rem/16);
}
.hotspots__hotspot:hover .hotspots__hotspot-inner {
    transform: scale(1.4);
}

.hotspots__detail-body{
    font-size: calc(14rem/16);
    line-height: calc(18/14);
    padding: calc(5rem/16);
    background-color: var(--color-secondary);
    color: #fff;
}

.hotspots__hotspot:hover .hotspots__detail-body:after {
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 3px solid var(--color-secondary);
}

.hotspots__detail{
    position: absolute;
    white-space: nowrap;
    left: 50%;
    transform: translateX(-50%) translateY(-100%);
    top: calc(-12rem/16);
    z-index: 5;
    display: none;
}

.hotspots__detail.is-open{
    display: block;
}
.hotspots__hotspot-icon {
   color: var(--color-text-default-dark);
}

.hotspots__hotspot-icon--big {
    font-size: calc(14rem/16);
    font-weight: bold;

}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(218, 0, 28, 0.38);
    }
    70% {
        box-shadow: 0 0 0 15px rgba(218, 0, 28, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(218, 0, 28, 0);
    }
}