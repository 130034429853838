.btn-outline-secondary:hover{
    color: #fff;
}
.btn-icon {
    display: flex;
}
.btn-icon__icon {
    font-size: calc(20rem/16);
    margin-right: calc(10rem/16);
}
.btn-nassfeld {
    background-color: var(--color-primary);
    color:#fff;
}
.btn-weissensee {
    background-color: var(--color-weissensee);
    color:#fff;
}
.btn-lesachtal {
    background-color: var(--color-lesachtal);
    color:#fff;
}
.btn-friaul {
    background-color: var(--color-friaul);
    color:#fff;
}
.btn-sm {
    border-radius: 0;
}
.btn-facebook {
    background-color: #3B5999;
    color:#fff;
    transition: opacity 0.2s ease-in;
}

.btn-google{
    background-color: #DC4E41;
    color:#fff;
    transition: opacity 0.2s ease-in;
}

.btn-google:hover,
.btn-facebook:hover {
    opacity: 0.8;
}

.btn-outline-white:hover {
    color: var(--color-secondary);
}

.btn-white,
.btn-white:hover,
.btn-white:focus,
.btn-white:active {
    border: none!important;
    outline: none!important;
    box-shadow: none!important;
}