.a-z-teaser .event-teaser__sub-title{
    margin-top: calc(10rem/16);

    @media screen and (min-width: 768px) and (max-width: 1399px){
        font-size: calc(14rem/16);
    }
}

.a-z-teaser .event-teaser__content{
    padding: calc(20rem/16);

    @media screen and (min-width: 1400px){
        padding: calc(25rem/16) calc(30rem/16);
    }
    
    @media screen and (min-width: 768px) and (max-width: 1200px){
       padding: calc(10rem/16);
    }
}

.a-z-teaser__icon{
    font-size: calc(55rem/16);

    @media screen and (min-width: 1200px) and (max-width: 1399px){
        font-size: calc(45rem/16);
    }

    @media screen and (min-width: 768px) and (max-width: 1199px){
        font-size: calc(35rem/16);
    }

    @media screen and (min-width: 1400px){
        font-size: calc(74rem/16);
    }
}

.a-z-teaser .event-teaser__title{
    @media screen and (min-width: 1200px) and (max-width: 1399px){
       font-size: calc(20rem/16);
    }
    @media screen and (min-width: 768px) and (max-width: 1199px){
        font-size: calc(16rem/16);
    }
}

.a-z-teaser .triangle-badge__text{
    @media screen and (min-width: 1200px) and (max-width: 1399px){
        font-size: calc(20rem/16);
    }

    @media screen and (min-width: 768px) and (max-width: 1199px){
        font-size: calc(16rem/16);
    }
    
    @media screen and (max-width: 767px){
       font-size: calc(22rem/16);
    }
}

.a-z-teaser .triangle-badge__icon{
    @media screen and (min-width: 1200px) and (max-width: 1399px){
        font-size: calc(45rem/16);
    }

    @media screen and (min-width: 768px) and (max-width: 1199px){
        font-size: calc(35rem/16);
    }
    
    @media screen and (max-width: 767px){
       font-size: calc(50rem/16);
    }
}

.a-z-teaser .event-teaser__location{
    @media screen and (min-width: 768px) and (max-width: 1199px){
        font-size: calc(12rem/16);
    }
}

.a-z-teaser .event-teaser__status{
    @media screen and (min-width: 768px) and (max-width: 1199px){
        font-size: calc(13rem/16);
    }
}