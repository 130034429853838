.tour-map__canvas {
    z-index: 1;
}

@media screen and (max-width: 767px) {
    .leaflet-map .oax .oax-ep-wrapper .alp-tpwiz-elprocont .alp-tpwiz-elpro-subcont{
        height: auto;
    }
    .leaflet-map{
        margin-bottom: calc(115rem/16);
    }
    .leaflet-map .oax .oax-ep-wrapper .alp-tpwiz-elprocont .alp-tpwiz-elpro-subcont .alp-x-elpro-slider-helptext{
        padding-top: calc(14rem/16);
    }
    .leaflet-map .oax .oax-ep-wrapper .alp-tpwiz-elprocont .alp-tpwiz-elpro-wrapper {
        padding-left: calc(31rem/16);
    }
}