.hero-small{
    position: relative;
}

.hero-small:before {
    content:'';
    position: absolute;
    bottom:0;
    left:0;
    width: 100%;
    height:80%;
    background: linear-gradient(180deg, rgba(61,61,61,0) 0%, var(--color-default-bg) 100%);
    z-index: 1;
    @media screen and (max-width: 767px) {
        height: 80%;
    }
}

.hero-small__bg-wrapper, .hero-small__img{
    height: 50vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    display: inherit;
}

.hero-small__content{
    position: absolute;
    bottom: 2%;
    left: 0;
    right: 0;
    z-index: 1;
}
.hero-small__content--has-region-indicator {
    @media screen and (max-width: 767px) {
        bottom: 38%;
    }
}

.hero-small__content .btn {
    @media screen and (max-width: 767px) {
        padding: calc(5rem/16);
        font-size: 1rem;
    }
}
.hero-small .hero__title {
    text-shadow: 0 0 calc(15rem/16) rgba(0,0,0,0.2);
}

.hero-small__bg {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}
.hero-small__bg-video {
    overflow:hidden;
    position:absolute;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.hero-small__bg-video__video, .hero-small__bg-video video, .hero-small__bg-video .vjs-poster{
    overflow:hidden;
    position:absolute;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    @media screen and (max-width: 767px) {
        min-height: calc(100vh - var(--main-nav-height--xs));
    }
}
.hero-small__bg-video .vjs-poster{
    min-width: 100vw!important;
    min-height: 100vh !important;
    width: auto !important;
}
.hero-small__bg-video .vjs-poster {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: var(--color-secondary);
}
.hero-small__bg-video .hero-small__bg-video__video:before {
    content:'';
    position: absolute;
    top:0;
    left:0;
    z-index: 9;
    width: 100%;
    height:100%;
    background-color: rgba(0,0,0,0.3);
    transition: background-color 0.4s ease;
}
.hero-small__bg-video .vjs-v7.hero-small__bg-video__video:before {
    background-color: rgba(0,0,0,0.7);
}
.hero-small__bg-video .vjs-has-started.hero-small__bg-video__video:before {
    background-color: rgba(0,0,0,0);
}
.hero-small__bg-video .vjs-big-play-button, .hero-small__bg-video .vjs-control-bar {
    display: none !important;
}