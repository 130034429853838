.triangle-animation__item-wrapper {
    transform-origin: top;
}
.triangle-animation__item-wrapper:nth-of-type(0) {
    transition: transform 0.12s cubic-bezier(0, 0.47, 1, 1.35);
}
.triangle-animation__item-wrapper:nth-of-type(1) {
    transition: transform 0.1s cubic-bezier(0, 0.47, 1, 1.35);
}
.triangle-animation__item-wrapper:nth-of-type(2) {
    transition: transform 0.12s cubic-bezier(0, 0.47, 1, 1.35);
}

@keyframes triangle {
    0% {
        fill: transparent;
        transform: translateY(40px) rotate3d(1, 1, 1, 56deg);
    }
    100% {
        fill: var(--color-text-default);
        transform: translateY(0px) rotate3d(1, 1, 1, 0deg);
    }
}
@keyframes triangle2 {
    0% {
        fill: transparent;
        transform: translateY(40px);
    }
    100% {
        fill: #fff;
        transform: translateY(-140px) translateX(43px) rotate(7deg);
    }
}
@keyframes triangleRed {
    0% {
        opacity: 0.5;
        fill: transparent;
        transform: translateY(40px) rotate3d(1, 1, 1, 60deg);
    }
    100% {
        opacity:1;
        fill: var(--color-primary);
        transform: translateY(0px) rotate3d(1, 1, 1, 0deg);
    }
}
