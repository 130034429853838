.title-block__title {
    text-transform: uppercase;
    color: var(--color-primary);
}
.title-block__sub-title {
    text-transform: uppercase;
    @media screen and (max-width: 767px){
       font-size: calc(14rem/16);
    }
}
.title-block__content {
    margin-top: calc(38rem/16);
}
.title-block--big .title-block__title {
    font-size: calc(50rem/16);
    line-height: 1;
    @media screen and (max-width: 767px){
        font-size: calc(28rem/16);
    }
}
.title-block--big .title-block__sub-title {
    font-size: calc(26rem/16);
    @media screen and (max-width: 767px){
        font-size: calc(14rem/16);
    }
}
.title-block--white .title-block__title {
    color:#fff;
}