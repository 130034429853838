.hero__countdown-wrapper {
    position: absolute;
    left:0;
    width: 100%;
    top: 30%;
    transform: translateY(-50%);
    text-align:center;
    overflow: hidden;
    @media screen and (max-width: 767px) {
        top: 25%;
    }
}
.hero__countdown-title {
    text-align: center;
    font-family: var(--font-default-bold);
    font-size: calc(16rem/16);
    margin-bottom: calc(30rem/16);
    text-transform: uppercase;
    text-shadow: 0 calc(2rem/16) calc(20rem/16) rgba(0, 0, 0, 0.15);
    @media screen and (max-width: 767px) {
        margin-bottom: calc(10rem/16);
        font-size: calc(14rem/16);
    }
}