.cookie-bar {
    background-color: var(--color-secondary);
    padding:calc(5rem/16) calc(20rem/16);
    @media screen and (max-width: 767px) {
        z-index: 999;
    }
}
.cookie-modal .modal-body {
    padding: calc(20rem/16);
}
.cookie-modal__label {
    font-size: calc(18rem/16);
}
.cookie-modal .modal-footer {
    padding: calc(20rem/16);
}
.cookie-bar__buttons {
    margin-top:calc(5rem/16);
}
.cookie-bar__accept {
    font-size: calc(14rem/16);
    padding: calc(10rem/16);
}

#CookiebotWidget {
    @media screen and (max-width: 767px) {
        bottom: calc(90rem/16) !important;
    }
}