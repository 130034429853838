.img-checkbox {
    position: relative;
    padding-left: 1.7em;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    margin-bottom: calc(12rem /16);
    height: calc(160rem / 16);
}
.img-checkbox.is-checked:before {
    border-color: var(--color-primary) transparent transparent;
    border-top: 220px solid var(--color-primary);
}
.img-checkbox:before {
    content: "";
    position: absolute;
    width: 0;
    left: 0;
    height: 0;
    border-color: #26262650 transparent transparent;
    border-style: solid;
    border-width: 0 250px 0 0;
    border-top: 220px solid #26262650;
}


.img-checkbox:before,
.img-checkbox:after {
    content: " ";
    display: table;
}

.img-checkbox:after {
    clear: both;
}

.img-checkbox>label{
    cursor: pointer;
}

.img-checkbox__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}

.img-checkbox__box {
    position: absolute;
    left:  1.75rem;
    margin-right: calc(7rem/16);
    overflow: hidden;
    border: 1px solid var(--color-primary);
    background: transparent;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    margin-top: 0.12em;
}

.img-checkbox__box:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 0.625rem;
    color: #fff;
    visibility: hidden;
}
.img-checkbox.is-checked .img-checkbox__box:before {
    visibility: visible;
}

.img-checkbox.is-checked .img-checkbox__box {
    border: 1px solid var(--color-dark);
}

.img-checkbox__input-group{
    display: flex;
    align-items: center;
    padding: 1.75rem;
}
.img-checkbox__input:focus ~ .img-checkbox__box {
    background-color: var(--color-primary);
}
.has-error .img-checkbox__box {
    border-color: var(--color-danger)
}
.img-checkbox__text {
    color: #fff;
    font-family: var(--font-default-bold);
    margin-left: calc(12rem/16);
    line-height: 1;
    text-transform: uppercase;
    display: block;
    overflow: hidden;
    position: relative;
}

.img-checkbox__input-group::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3;
    pointer-events: auto;
    content: "";
    background-color: rgba(0,0,0,0);
}
