:root {
    --container-max-width: calc(1640rem/16);
    --container-md-max-width: calc(1330rem/16);
    --container-narrow-max-width: calc(1056rem/16);
    --container-xs-max-width: calc(820rem/16)
}

.container.container {
    max-width: var(--container-max-width);
    width: 100%;
}
.container.container-narrow {
    max-width: var(--container-narrow-max-width);
    width: 100%;
}
.container.container-xs {
    max-width: var(--container-xs-max-width);
    width: 100%;
}
.container.container-md {
    max-width: var(--container-md-max-width);
    width: 100%;
}
.container--no-padding-xs {
    @media screen and (max-width: 767px) {
        padding: 0;
    }
}
@media screen and (min-width: 768px) {
    .container__break-right-half-md {
        width: calc(50vw - calc(var(--row-gutter) / 2));
    }

}
@media screen and (min-width: 1700px) {
    .container__move-right {
        margin-left: calc((100vw - var(--container-max-width)) / 2);
    }
}
@media screen and (min-width: 768px) {
    .container__break-left-half-md {
        width: calc(50vw - calc(var(--row-gutter) / 2));
        margin-left: 100%;
        transform: translateX(-100%);
    }
}
