.slick-slide > div {
    display: flex;
}
.slider {
    margin-bottom: 0;
}
.slider-arrow {
    font-size: calc(25rem/16);
    border-radius: 50%;
    transition: border-color 0.2s ease, color 0.3s ease;
}
.slider-arrow:not(.slick-disabled):hover {
    animation: pulse-arrow 2s infinite;
    border-color: var(--color-text-default);
    color: var(--color-text-default);
}
.slider-arrow--outlines {
    width: calc(60rem/16);
    height: calc(60rem/16);
    border: calc(1rem/16) solid var(--color-text-default);
    color: var(--color-text-default);
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;

    @media screen and (min-width: 1200px){
        width: calc(110rem/16);
        height: calc(110rem/16);
    }
    
    @media screen and (max-width: 767px){
       font-size: calc(15rem/16);
        width: calc(42rem/16);
        height: calc(42rem/16);
    }
}
.slider-arrow--outlines.slick-disabled {
    opacity: 0.4 !important;
    pointer-events: initial;
}
.slider--has-default-arrows .slider-arrow--outlines {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;
    border-color:#fff;
    color:#fff;
    @media screen and (max-width: 767px) {
        top: 30%;
    }
}
.slider--has-default-arrows .slider-arrow--outlines.arrow-prev {
    left: calc(10rem/16);
}
.slider--has-default-arrows .slider-arrow--outlines.arrow-next {
    right: calc(10rem/16);
}
@keyframes pulse-arrow {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.38);
    }
    70% {
        box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(204,169,44, 0);
    }
}
.slider--has-default-arrows .slider-arrow--outlines {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;
    border-color:#fff;
    color:#fff;
    @media screen and (max-width: 767px) {
        top: 30%;
    }
}
.slider--has-simple-arrows .slider-arrow--outlines {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;
    border: none;
    color:#fff;
    opacity:1;
    width: calc(50rem/16);
    text-shadow: 0 calc(2rem/16) calc(20rem/16) #000000;
    height: calc(50rem/16);
}
.slider--has-simple-arrows .arrow-prev {
    left: calc(10rem/16);
}
.slider--has-simple-arrows .arrow-next {
    right: calc(10rem/16);
}