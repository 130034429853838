.navbar-parent{
    position: absolute;
    left: 0;
    right: 0;
    z-index: 200;
}
.navbar-parent.is-affix{
    position: fixed;
    top:0;
    @media screen and (min-width: 768px) {
        transform:translateY(0%);
        transition:transform 0.22s ease;
        will-change: transform;
    }
}
.navbar-parent.not-is-affix {
    position: absolute;
    top:0;
    @media screen and (min-width: 768px) {
        position: fixed;
        transform:translateY(-100%);
        transition:transform 0.22s ease;
    }
}
.navbar-main{
    background-color: #fff;
    color: var(--color-default);
    padding: 0;
    transition: all 450ms ease;

    @media screen and (min-width: 768px){
       height: var(--main-nav-height);
    }
    
    @media screen and (max-width: 767px){
       height: calc(65rem/16);
    }
}
.navbar-transparent .navbar-main{
    @media screen and (min-width: 768px){
        background: transparent;
    }
}
.navbar-transparent.dropdown-is-open .navbar-main{
    @media screen and (min-width: 768px){
        background: #fff;
    }
}
.navbar-transparent:before {
    @media screen and (min-width: 768px) {
        content: '';
        position: absolute;
        width: 100%;
        height: 200%;
        pointer-events: none;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0) 100%);
    }
}
.navbar-transparent.is-affix:before, .navbar-transparent.not-is-affix:before, .navbar-transparent.is-transitioning:before {
    @media screen and (min-width: 768px) {
        background: transparent;
    }
}

.navbar-transparent.is-affix .navbar-main,
.navbar-transparent.is-transitioning .navbar-main{
    @media screen and (min-width: 768px){
        background: #fff;
    }
}

.navbar-main-container{
    display: flex;
    flex-grow: 1;
    align-items: center;

    @media screen and (max-width: 767px){
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items:center;
    }
}

.main-nav,
.navbar-top{
    @media screen and (min-width: 1100px){
        margin-right: calc(15rem/16);
    }
}

.main-nav>.navbar-nav>.nav-item--button{
    @media screen and (min-width: 1200px){
       margin-left: calc(15rem/16);
    }
}
.main-nav>.navbar-nav>.nav-item--button .btn {
    padding-left: calc(15rem/16);
    padding-right: calc(15rem/16);
}
.navbar-transparent:not(.is-affix) .nav-item--button .btn {
    background-color: var(--color-secondary);
    color:#fff;
}
.main-nav .nav-link.nav-link-main{
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    font-size: calc(22rem/16);
    padding: calc(8rem/16) calc(20rem/16);
    margin-top: calc(12rem/16);
    overflow: hidden;
    position: relative;

    @media screen and (min-width: 1540px){
       font-size: calc(14rem/16);
        padding: calc(20rem/16) calc(15rem/16) calc(30rem/16);
    }

    @media screen and (min-width: 1299px){
        font-size: calc(12rem/16);
    }
    
    @media screen and (min-width: 768px){
        font-size: calc(10rem/16);
        padding: calc(23rem/16) calc(9rem/16) calc(30rem/16);
    }
}

.main-nav .nav-link.nav-link-main:after{
    @media screen and (min-width: 768px){
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: calc(6rem/16);
        background-color: var(--color-primary);
        transform: translateY(calc(100% + 16px));
        transition: all .25s ease;
    }

}

.main-nav .nav-link.nav-link-main:hover:after,
.main-nav .active .nav-link.nav-link-main:after{
    transform: translateY(0);
    opacity:1;
}

.main-nav>.navbar-nav .nav-item.active-highlight>.nav-link{
    @media screen and (max-width: 767px){
       background-color: #f5f5f5;
    }
}
.navbar-brand{
    @media screen and (max-width: 991px) and (min-width: 768px){
        margin: 0;
    }
    @media screen and (max-width: 767px){
        display: block;
        margin: 0;
    }
}

.navbar-brand img{
    @media screen and (max-width: 1599px) and (min-width: 1300px) {
        width: calc(152rem/16);
    }
    @media screen and (min-width: 1600px) {
        width: calc(152rem/16);
    }
    @media screen and (max-width: 1299px) and (min-width: 768px) {
        width: calc(100rem/16);
    }
    @media screen and (max-width: 767px) and (min-width: 350px){
        max-width: calc(102rem/16);
        max-height: calc(52rem /16);
        width: auto;
        height: auto;
    }
    @media screen and (max-width: 349px){
        max-width: calc(66rem/16);
        max-height: calc(40rem /16);
        width: auto;
        height: auto;
    }

}
.nav-open .navbar-brand{
    @media screen and (max-width: 767px){
        display: none;
    }
}


.navbar-logo{
    @media screen and (max-width: 767px){
       display: none;
    }
}
.nav-open .navbar-logo{
    @media screen and (max-width: 767px){
        display: block;
    }
}

.navbar-logo__img {
    width: auto;

    @media screen and (min-width: 768px) {
        height: calc(80rem/16);
    }

    @media screen and (min-width: 1500px) {
        height: calc(90rem/16);
    }

    @media screen and (max-width: 767px) {
        width: calc(102rem/16);
        height: auto;
    }
}

.main-nav>.navbar-nav{
    @media screen and (min-width: 768px){
       height: calc(84rem/16);
        align-items: center;
    }
}

.navbar-main .nav-item--button .btn{
    @media screen and (min-width: 1150px) and (max-width: 1400px){
        padding: calc(15rem/16);
        font-size: calc(12rem/16);
    }

    @media screen and (min-width: 768px) and (max-width: 1150px){
        font-size: calc(11rem/16);
        padding: calc(10rem/16) calc(10rem/16);

    }
}

.nav-item--button__hiding-text{
    @media screen and (min-width: 768px) and (max-width: 1150px){
       display: none;
    }
}

.navbar-right{
    @media screen and (max-width: 767px){
       margin-left: calc(20rem/16);
    }
}
.navbar__inquiry-btn-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    @media screen and (max-width: 767px) {
        justify-content: flex-end;
        margin-right: calc(10rem/16);
    }
}
.navbar__inquiry-btn {
    @media screen and (max-width: 767px) {
        padding: calc(5rem/16) calc(10rem/16);
        font-size: calc(12rem/16);
    }
}
@media screen and (max-width: 767px){

    .main-nav{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .main-nav__bottom{
        text-align: center;
    }
    
    .main-nav__bottom__list{
        margin-bottom: calc(24rem/16);
    }

    .main-nav__bottom__list .list-inline-item:not(:last-child){
        margin-right: calc(30rem/16);
    }

    .navbar-toggler{
        color: #fff;
        font-size: calc(12rem/16);
        font-family: var(--font-default-bold);
        text-transform: uppercase;
        height: calc(65rem/16);
    }

    .navbar-toggler--menu{
        background-color: var(--color-primary);
        max-width: calc(80rem/16);
        font-size: calc(11rem/16);
    }

    .navbar-toggler--booking{
        background-color: var(--color-text-default-dark);
        max-width: calc(117rem/16);
        font-size: calc(11rem/16);
    }

   /* .nav-open .navbar-toggler--booking{
        opacity: 0;
        visibility: hidden;
    }*/

    .navbar-toggler__icon{
        display: none;
        transform: rotate(45deg);
        font-size: calc(18rem/16);
    }

    .navbar-toggler.is-open .navbar-toggler__text{
        display: none;
    }

    .navbar-toggler.is-open .navbar-toggler__icon{
        display: inline-block;
    }

    .navbar-search{
        height: calc(65rem/16);
        padding: calc(16rem/16);
        font-size: calc(22rem/16);
        align-items: center;
        justify-content: center;
        display: flex;
    }

    .navbar-overlay-menu{
        position: fixed;
        top: calc(65rem/16);
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #fff;
        transform: translateX(100%);
        transition: all 250ms ease;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }

    .navbar-overlay-menu--booking{
        padding-left: calc(20rem/16);
        padding-right: calc(20rem/16);
    }
    .main-nav.navbar-overlay-menu{
        padding-bottom: calc(50rem/16);
    }
    
    .navbar-overlay-menu .subnav__btn{
        margin: 0 calc(-20rem/16);
    }

    .navbar-overlay-menu.is-open{
        transform: translateX(0);
    }

    .navbar-overlay-menu__goback-btn{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        font-size: calc(20rem/16);
        text-transform: none;
        padding: calc(20rem/16);
    }

    .scrolling-container{
        overflow-x:hidden;
        overflow-y: visible;
        -webkit-overflow-scrolling: touch;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        padding: 0 0 calc(70rem/16);
    }
    .nav-link__arrow--mobile{
        position: relative;
    }

    .nav-link__arrow--mobile:before{
        content: var(--icon-arrow-down-bold);
        position: absolute;
        font-size: calc(10rem /16);
        font-family: "iconfont";
        color: var(--color-secondary);
        top: 50%;
        transform: translateY(-50%) rotate(270deg);
        right: calc(20rem/16);
    }
}
