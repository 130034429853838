.text-teaser {
    padding: calc(20rem /16);
    background: #fff;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media screen and (min-width: 768px){
        padding: clamp(calc(20rem /16), 1.75vw, calc(36rem /16));
    }
}

.text-teaser.text-teaser--big {
    @media screen and (min-width: 1399px){
        padding: clamp(calc(20rem /16), 1.75vw, calc(36rem /16)) clamp(calc(36rem /16), 3vw, calc(60rem /16));
    }
}
.text-teaser__title {
    text-transform: uppercase;
    line-height: 1;
    font-family: var(--font-default-bold);
    font-size: calc(20rem /16);
    margin-bottom: calc(12rem /16);
    color: var(--color-primary);
    @media screen and (min-width: 768px){
        font-size: clamp(calc(16rem /16), 1.5vw, calc(24rem /16));
        margin-bottom: clamp(calc(10rem /16), 1vw, calc(20rem /16));;
    }
}

.text-teaser__wysiwyg {
    color: var(--color-secondary);
    @media screen and (min-width: 768px){
        font-size: clamp(calc(10rem /16), 1vw, calc(16rem /16));
    }
}

.text-teaser__wysiwyg > p,
.text-teaser__wysiwyg > ol,
.text-teaser__wysiwyg > ul,
.text-teaser__wysiwyg > table {
    margin-bottom: calc(12rem /16);
}
.text-teaser__wysiwyg li+li{
    margin-top: calc(4rem/16);
}

.text-teaser__btn {
    @media screen and (min-width: 768px) and (max-width: 1100px){
        padding: calc(8rem/16) calc(20rem/16);
    }

    @media screen and (max-width: 1399px){
        padding: calc(7rem/16) calc(20rem/16);
        font-size: calc(13rem/16);
        margin-top: calc(12rem / 16);
    }
}

.text-teaser.text-teaser__pauschal {
    position: relative;
    padding-bottom: clamp(calc(30rem /16), 2vw, calc(48rem /16));
}

.text-teaser__pauschal .text-teaser__btn {
    position: absolute;
    bottom: -1.625rem;
    left: 50%;
    transform: translateX(-50%);
    width: 85%;
}
