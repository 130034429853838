.webcam-slider__thumbs .slick-list {
    overflow: visible;
}
.webcam-slider {
    overflow: hidden;
}
.webcam-slider__thumbs {
    margin-left: calc(-19rem/16);
    margin-right: calc(-19rem/16);
    @media screen and (min-width: 768px) {
        margin-top: calc(-100rem/16);
    }
}
.webcam-slider__thumbs-wrapper {
    position: relative;
}
.webcam-slider__thumb-item {
    padding-left: calc(4rem/16);
    padding-right: calc(4rem/16);

    @media screen and (min-width: 768px) {
        padding-bottom: calc(150rem/16); /* needed for drag */
        padding-left: calc(19rem/16);
        padding-right: calc(19rem/16);
    }
}
.webcam-slider__drag-indicator {
    height: calc(110rem/16);
    position: absolute;
    bottom:0;
    display: grid;
    font-size: calc(40rem/16);
    grid-template-columns: minmax(20px,1fr) auto minmax(20px,1fr);
    grid-gap: 1.625rem;
    width: 100%;
    left:0;
    right:0;
    pointer-events: none;
}
.webcam-slider__drag-indicator:after, .webcam-slider__drag-indicator:before {
    content: "";
    background-image: linear-gradient(to right, #fff 10%, rgba(148, 0, 16, 0) 0%);
    background-position: center;
    background-size: calc(10rem/16) calc(1rem/16);
    background-repeat: repeat-x;
}
.webcam-slider__drag-indicator__icon {
    display: flex;
    align-items:center;
}
.webcam-slider__arrow {
    position: absolute;
    font-size: calc(60rem/16);
    background-color: transparent;
    color:#fff;
    box-shadow: none;
    border: none;
    top: 50%;
    left:0;
    z-index: 9;
    transform: translateY(-50%);
    padding: calc(30rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(20rem/16);
        bottom:0;
        top: inherit;
        transform: none;
        padding: calc(10rem/16);
    }
}
.webcam-slider__arrow.arrow-next {
    right:0;
    left: initial;
}