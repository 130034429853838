.snow-item {
    background-color: var(--color-dark);
    padding: calc(60rem/16) calc(50rem/16) calc(60rem/16);
    text-align: center;
    height: 100%;
    @media screen and (max-width: 1499px) {
        padding: calc(60rem/16) calc(10rem/16);
    }
    @media screen and (max-width: 767px) {
        margin-bottom: calc(20rem/16);
        padding: calc(20rem/16) calc(10rem/16);
        height: auto;
    }
}
.snow-item__title {
    font-family: var(--font-default-bold);
    line-height:1;
    text-transform:uppercase;
    margin-bottom: 0;
    @media screen and (max-width: 1499px) {
        font-size: calc(18rem/16);
    }
}
.snow-item__icon {
    font-size: calc(120rem/16);
    display: block;
    margin: calc(50rem/16) 0;
    @media screen and (max-width: 1499px) {
        font-size: calc(60rem/16);
    }
}
.snow-item__value {
    font-size: calc(40rem/16);
    line-height: calc(49/40);
    font-family: var(--font-default-bold);
    @media screen and (max-width: 1499px) {
        font-size: calc(28rem/16);
    }
}
.snow-item__value-label {
    text-transform: uppercase;
    color: var(--color-light);
    font-size: calc(16rem/16);
}
.snow-item__date {
    margin-top: calc(5rem/16);
}