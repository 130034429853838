.form-group__white .form-control{
    color: #fff;
    border-color: #fff;
}

.form-group__search:before {
    content: var(--icon-search);
    font-family: iconfont;
    color: inherit;
    speak: none;
    position: absolute;
    bottom: 50%;
    transform: translateY(50%);
    right: calc(10rem/16);
    font-size: calc(20rem/16);
    pointer-events: none;
}


.select {
    position: relative;
}
.select select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}
.select select::-ms-expand {
    display: none;
}
.select:before {
    font-family: "iconfont";
    content: var(--icon-arrow-dropdown);
    color: var(--color-form-elements);
    speak: none;
    position: absolute;
    pointer-events: none;
    font-size: calc(10rem / 16);
    top: 50%;
    right: calc(2rem / 16);
    transform: translateY(-50%);
    z-index: 1;
}
select.form-control:not([size]):not([multiple]) {
    height: calc(48rem / 16);
}
.select--bordered:before {
    right: calc(20rem / 16);
}
.form-control-label {
    color: var(--color-form-elements);
}
.form-control--border {
    border: calc(2rem/16) solid #d0d0d0;
}
.form--white-labels .floating-label {
    color: #fff;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus{
    -webkit-box-shadow: 0 0 0 3rem var(--color-default-bg) inset!important;
    -webkit-text-fill-color: var(--color-white)!important;
    background-image: none !important;
}

#loginModal input:-webkit-autofill,
#loginModal input:-webkit-autofill:hover,
#loginModal input:-webkit-autofill:focus{
    -webkit-box-shadow: 0 0 0 3rem #fff inset!important;
    -webkit-text-fill-color: var(--color-default)!important;
    background-image: none !important;
}