.navbar-top{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media screen and (min-width: 768px){
       height: calc(50rem/16);
    }
}

.list-icons{
    font-size: calc(18rem/16);
    line-height: 1;

    @media screen and (min-width: 1400px){
        font-size: calc(24rem/16);
    }
}

.list-icons--social {
    font-size: calc(16rem/16);
    line-height: 1;
    @media screen and (max-width: 1199px) and (min-width: 768px) {
        display: none;
    }
    @media screen and (min-width: 1400px){
        font-size: calc(18rem/16);
    }
}

.navbar-main .list-icons .list-inline-item:not(:last-child){
    margin-right: calc(25rem/16);

    @media screen and (min-width: 768px){
        margin-right: calc(12rem/16);
    }

    @media screen and (min-width: 1400px){
        margin-right: calc(15rem/16);
    }

    @media screen and (min-width: 1600px){
        margin-right: calc(30rem/16);
    }
}

.navbar-top .list-icons{
    padding: 0 calc(30rem/16);
    @media screen and (min-width: 768px){
        padding: 0 calc(20rem/16);
    }
    @media screen and (min-width: 1400px){
        padding: 0 calc(22rem/16);
    }
    @media screen and (min-width: 1600px){
        padding: 0 calc(30rem/16);
    }
}

.navbar-top .list-icons + .list-icons{
    border-left: 1px solid #979797;
}

.navbar-top__dropdown + .navbar-top__dropdown{
    margin-left: calc(30rem/16);
    align-items: center;
}
.navbar-top__dropdown {
    @media screen and (max-width: 1199px) and (min-width: 768px) {
        font-size: calc(12rem/16);
    }
}

/*Login*/
.navbar-top-login {
    display: flex;
}
.navbar-top-login__icon-wrapper {
    background-color: var(--color-secondary);
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.navbar-top-login__icon {
    color: #fff;
    font-size: calc(15rem /16);
}
.navbar-top-login__text{
    margin-left: calc(10rem /16);
    font-size: calc(14rem /16);
    font-family: var(--font-default-bold);
}
.navbar-top-login__image-wrapper{
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
}
.navbar-top__dropdown--profile .dropdown-toggle:after {
    content: "";
    display: none;
}
.navbar-top__dropdown-menu {
    box-shadow: 0 0 10px 0 rgba(61,61,61,0.1);
    margin-top: calc(12rem /16);
    border: none;
    padding: calc(12rem / 16);
    left: 50%;
    right: unset;
    transform: translateX(-50%);
    @media screen and (min-width: 768px){
        padding: calc(48rem / 16);
    }
}
.navbar-top__dropdown-menu:before {
    content: "";
    position: absolute;
    top: calc(-12rem /16);
    left: calc(50% - (12rem /16));
    width: 0;
    height: 0;
    border-left: calc(12rem /16) solid transparent;
    border-right: calc(12rem /16) solid transparent;
    border-bottom: calc(12rem /16) solid #fff;
}
.navbar-top__dropdown-item {
    font-size: calc(12rem / 16);
    font-family: var(--font-default-bold);
    text-transform: uppercase;
}
.navbar-top__dropdown-item:hover {
   color: var(--color-primary);
    background-color: #fff;
}
.navbar-top__dropdown-item + .navbar-top__dropdown-item {
    margin-top: calc(16rem /16);
    @media screen and (min-width: 768px){
        margin-top: calc(24rem /16);
    }
}
.navbar-top__dropdown-item.navbar-top__dropdown-item--light {
    opacity: .7;
}