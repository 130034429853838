.card-teaser__embed {
    padding-top: calc(340 / 510 * 100%);
}
.card-teaser {
    height:100%;
    overflow: hidden;
    border-radius: calc(10rem/16);
    box-shadow: 0 calc(40rem/16) calc(40rem/16) 0 rgba(0,0,0,0.2);
    background-color: #fff;
    color: var(--color-text-default-dark);
    cursor: pointer;
    will-change: box-shadow;
    display: flex;
    flex-direction: column;
    transition: box-shadow 0.4s ease;
}
.card-teaser__embed:after {
    content:'';
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    z-index: 1;
    opacity: 0.3;
    transition: opacity 0.5s cubic-bezier(0.7, 0, 0.5, 1);
    background: linear-gradient(180deg, rgba(61,61,61,0) 0%, rgba(75, 75, 75, 0.89) 100%);
}
.card-teaser__title {
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    margin-bottom: calc(5rem/16);
}
.card-teaser__sub-title {
    text-transform: uppercase;
}
.card-teaser__body {
    padding: calc(30rem/16) calc(40rem/16);
    display: flex;
    flex-direction: column;
    height: auto;
    flex: 1;
    @media screen and (max-width: 1199px) {
        padding: calc(20rem/16);
    }
}
.card-teaser__content {
    margin-top: calc(26rem/16);
    margin-bottom: calc(50rem/16);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    @media screen and (max-width: 767px) {
        margin-top: calc(10rem/16);
        margin-bottom: calc(20rem/16);
    }
}
.card-teaser__btn-wrapper {
    margin-top: auto;
    text-align: center;
}
.card-teaser--warning .card-teaser__media {
    background-color: var(--color-primary);
    color:#fff;
    font-size: calc(120rem/16);
    display: flex;
    justify-content:center;
    align-items:center;
}
@media screen and (min-width: 768px) {
    .card-teaser:hover {
        box-shadow: 0 2.5rem 2.5rem 0 rgba(0, 0, 0, 0.52);
    }
    .card-teaser__media {
        transition: transform 0.5s cubic-bezier(0.7, 0, 0.5, 1);
    }
    .card-teaser:hover .card-teaser__media {
        transform: scale(1.1);
    }
    .card-teaser:hover .card-teaser__embed:after {
        opacity:0;
    }
    .card-teaser:hover .card-teaser__btn {
        transition: background-color 0.4s cubic-bezier(0.7, 0, 0.4, 1);
        border-color: var(--color-primary);
        background-color: var(--color-primary);
    }
}