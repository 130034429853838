.buddy-sticky-badge {
    position: fixed;
    top: auto;
    right: 0;
    bottom: calc(100rem/16);
    width: calc(426rem/16);
    height: calc(264rem/16);
    background-color: var(--color-primary);
    border-radius: calc(32rem/16) 0 0 calc(32rem/16);
    z-index: 1000;
    transform: translateX(100%);
    transition: transform 500ms ease;
    @media screen and (max-width: 767px) {
        width: calc(300rem/16);
        height: calc(210rem/16);
    }
}
.buddy-sticky-badge.is-visible {
    transform: translateX(0);
}
.buddy-sticky-badge__close-btn {
    position: absolute;
    top: calc(16rem/16);
    right: calc(16rem/16);
    font-size: calc(24rem/16);
    cursor: pointer;
    transform: rotate(0);
    transition: transform 300ms ease;
    transform-origin: 50% 40%;
    @media screen and (max-width: 767px) {
        top: calc(10rem/16);
        right: calc(10rem/16);
        font-size: calc(20rem/16);
    }
}
.buddy-sticky-badge__close-btn:hover {
    transform: rotate(180deg);
}
.buddy-sticky-badge__title {
    font-family: var(--font-default-bold);
    font-size: calc(28rem/16);
    line-height: calc(35rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(20rem/16);
        line-height: calc(26rem/16);
    }
}
.buddy-sticky-badge__avatar {
    width: calc(106rem/16);
    height: calc(106rem/16);
    position: absolute;
    top: calc(-22rem/16);
    left: 0;
    background-color: white;
    border-radius: 50%;
    border: calc(3rem/16) solid var(--color-primary);
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 767px) {
        width: calc(90rem/16);
        height: calc(90rem/16);
    }
}
.buddy-sticky-badge__avatar-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}
.buddy-sticky-badge__content {
    padding: calc(19rem/16) calc(40rem/16) calc(34rem/16);
    @media screen and (max-width: 767px) {
        padding: calc(16rem/16) calc(20rem/16) calc(24rem/16);
    }
}
.buddy-sticky-badge__claim {
    width: calc(107rem/16);
    height: calc(70rem/16);
    margin-top: calc(13rem/16);
    margin-left: calc(142rem/16);
    @media screen and (max-width: 767px) {
        width: calc(80rem/16);
        height: calc(50rem/16);
        margin-top: calc(10rem/16);
        margin-left: calc(120rem/16);
    }
}
.buddy-sticky-badge__claim-img {
    object-fit: contain;
}
.buddy-sticky-badge__cta-btn {
    margin-top: calc(19rem/16);
    @media screen and (max-width: 767px) {
        margin-top: calc(16rem/16);
    }
}