.floating-label {
    position: absolute;
    left: 0;
    top: calc(11rem / 16);
    transition: transform 200ms ease-in-out;
    transform-origin: left top;
    pointer-events: none;
    z-index: 1;
    color: var(--color-form-elements);
}
.floating-label--small {
    font-size: calc(12rem/16);
    top: calc(16rem / 16);
}
.form-group__white .floating-label{
    color: #fff;
}

textarea.form-control.has-value ~ .floating-label,
textarea.form-control:focus ~ .floating-label{
    transform: translateY(calc(-17rem / 16)) scale(.75);
}
.form-control:focus ~ .floating-label,
select ~ .floating-label,
.form-control.has-value ~ .floating-label,
.form-group.has-value .floating-label {
    transform: translateY(calc(-17rem / 16)) scale(.75);
}

.form-control:-webkit-autofill ~ .form-control-label,
.form-control:-webkit-autofill:focus ~ .form-control-label {
    transform: translateY(calc(-17rem / 16)) scale(.75);
}
.form-group--text-small .form-check-label {
    font-size: calc(14rem / 16);
    padding-top: calc(2rem /16);
}