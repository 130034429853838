.simple-map__content {
    margin-top: calc(100rem/16);
    @media screen and (max-width: 767px) {
        margin-top: calc(40rem/16);
    }
}
.simple-map__address-title {
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    margin-bottom: calc(40rem/16);
    @media screen and (max-width: 767px) {
        margin-bottom: calc(20rem/16);
    }
}
.simple-map__address {
    line-height: calc(24/16);
    margin-bottom: calc(30rem/16);
    @media screen and (max-width: 767px) {
        margin-bottom: calc(20rem/16);
    }
}
.simple-map__address a:hover {
    color: var(--color-primary);
}