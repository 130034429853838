:root {
    --sub-nav-height: calc(60rem/16);
}
.subnav-placeholder{
    position: absolute;
    left: 0;
    right: 0;
    top: var(--main-nav-height);
}

.subnav{
    left: 0;
    right: 0;
    z-index: 10;

    @media screen and (min-width: 768px){
        position: absolute;
        visibility: hidden;
        opacity: 0;
        height: var(--sub-nav-height);
        background-color: var(--color-subnav-bg);
    }

    @media screen and (max-width: 767px){
        top: 0;
    }
}

.main-nav .navbar-nav>.nav-item.active .subnav{
    visibility: visible;
    opacity: 1;

    @media screen and (max-width: 767px){
       transform: translateX(0);
    }
}
.subnav__container {
    @media screen and (min-width: 768px) and (max-width: 1599px){
        padding: 0;
    }
}
.subnav__btn{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-subnav-button-bg);
    color: var(--color-subnav-button-color);
    border: none;

    @media screen and (max-width: 767px){
        height: calc(75rem/16);
        font-size: calc(20rem/16);
    }

    @media screen and (min-width: 1400px) and (max-width: 1599px){
        padding: 1rem calc(10rem/16);
    }

    @media screen and (min-width: 768px) and (max-width: 1399px){
        font-size: calc(10rem/16);
        padding: 1rem calc(3rem/16);
    }
}
.subnav__btn.btn-light{
    color: #fff;
}
.subnav__btn.btn-light:hover{
    color: var(--color-text-default-dark);
}

.subnav__nav{
    @media screen and (max-width: 767px){
       display: block;
        width: 100%;
        margin-top: calc(40rem/16);
    }
}

.subnav__nav .nav-item+.nav-item{
    @media screen and (max-width: 767px){
       margin-top: calc(10rem/16);
    }
}

.navbar-nav .subnav__nav .nav-link{
    height: 100%;
    color: var(--color-text-default-dark);
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding: calc(8rem/16) calc(20rem/16);
    font-size: calc(20rem/16);

    @media screen and (min-width: 768px){
        color: #fff;
        font-size: calc(10rem/16);
        padding: calc(8rem/16) calc(6rem/16);
    }

    @media screen and (min-width: 1250px){
        font-size: calc(12rem/16);
        padding: calc(8rem/16) calc(20rem/16);
    }
}

.subnav__nav .nav-link:before{
    @media screen and (min-width: 768px){
        content: '';
        position: absolute;
        left: calc(20rem/16);
        right: calc(20rem/16);
        bottom: 0;
        height: calc(6rem/16);
        background-color: var(--color-primary);
        transform: translateY(105%);
        transition: all 250ms ease;
    }
}

.subnav__nav>.nav-item:hover .nav-link:before{
    @media screen and (min-width: 768px){
        transform: translateY(0);

    }
}

.subnav__nav .nav-item.active .nav-link{
    color: var(--color-primary);
}