:root {
    --img-slide-transform-value: calc(27rem/16);
}
.img-slide {
    height: calc(600rem/16);
    background-size: cover;
    position: relative;
    margin-bottom: var(--img-slide-transform-value);
    @media screen and (max-width: 767px) {
        height: calc(300rem/16);
        margin-bottom: calc(var(--img-slide-transform-value) + 40rem/16);
    }
}
.img-slide:before {
    content:'';
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(61,61,61,0.6) 100%);
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 70%;
    bottom:0;
    left:0;
}
.img-slide__inner {
    position: absolute;
    width: 100%;
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content:flex-end;
    z-index: 2;
    top: var(--img-slide-transform-value);
}
.img-slide__title {
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    @media screen and (min-width: 768px) {
        padding-left: calc(80rem/16);
    }
}
.img-slide__content {
    font-family: var(--font-default-bold);
    max-width: calc(300rem/16);
}