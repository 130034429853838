.arrival-slide-area{
    background-color: #e5e5e5;
    color: var(--color-default);
    overflow: hidden;
}

.arrival-slide-area__title {
    font-family: var(--font-headline);
}

.arrival-slide-area__body{
    padding: calc(50rem/16) 0;

    @media screen and (min-width: 768px){
        max-width: calc(310rem/16);
        width: 100%;
    }
}

.arrival-slide-area__map{
    @media screen and (min-width: 768px){
        position: absolute;
        right: calc(-360rem/16);
        top: calc(20rem/16);
        z-index: 2;
        width: 100%;
    }
    @media screen and (max-width: 767px){
       margin-top: calc(20rem/16);
    }
}
.arrival-slide-area__wrapper{
    position: relative;
    display: flex;
    align-items:center;
}
.arrival-slide-area__wrapper:after {
    pointer-events: none
}

@media screen and (min-width: 768px){
    .arrival-slide-area__wrapper:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left:calc(-2rem/16);
        right: calc(1rem/16);
        background-image: url(/static/img/decorators/triangle-arrival.svg);
        transform: translateX(100%);
        z-index: 1;
        width: 100%;
        background-size: contain;
        background-repeat: no-repeat;
    }
    
}
