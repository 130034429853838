.countdown-item__circle {
    width: calc(100rem/16);
    height: calc(100rem/16);
    border: 2px solid #fff;
    margin: 0 auto;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calc(30rem/16);
    line-height:1;
    text-align: center;
    font-family: var(--font-default-bold);
    box-shadow: 0 0 calc(20rem/16) rgba(0,0,0,.15);
    text-shadow: 0 calc(2rem/16) calc(20rem/16) rgba(0, 0, 0, 0.23);
    @media screen and (max-width: 767px) {
        width: calc(60rem/16);
        height: calc(60rem/16);
        font-size: calc(18rem/16);
    }
}
.countdown-item__text {
    text-shadow: 0 calc(2rem/16) calc(20rem/16) rgba(0, 0, 0, 0.23);
    text-transform: uppercase;
    font-size: calc(14rem/16);
    margin-top: calc(10rem/16);
    font-family: var(--font-default-bold);
    @media screen and (max-width: 767px) {
        font-size: calc(12rem/16);
    }
}