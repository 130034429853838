.content-block + .content-block,
.content-block + .pimcore_area_content > .content-block, .pimcore_area_content + .content-block,
.pimcore_area_content + .pimcore_area_content > .content-block {
    margin-top: calc(50rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(120rem/16);
    }
}
.content-block + .sharing-area.content-block, .pimcore_area_content + .sharing-area.content-block{
    margin-top: calc(20rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(50rem/16);
    }
}