.slide {
    padding-top: calc(37rem/16);
    padding-bottom: calc(37rem/16);
}
.slide--small {
    padding-top: calc(20rem/16);
    padding-bottom: calc(20rem/16);
}
@media screen and (min-width: 768px) {
    .slide {
        padding-top: calc(110rem/16);
        padding-bottom: calc(110rem/16);
    }
    .slide--small {
        padding-top: calc(40rem/16);
        padding-bottom: calc(40rem/16);
    }
}
