.tosc__container {
    min-height: 60vh;
    background-color:#fff;
}
.navbar-tosc.not-is-affix.navbar-parent {
    @media screen and (min-width: 768px) {
        position: absolute;
        transform: none;
        transition:transform 0.22s ease;
    }
}