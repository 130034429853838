.profile-intro{
    background-color: var(--color-dark);
    padding-top: calc(70rem/16);
    padding-bottom: calc(70rem/16);
    @media screen and (min-width: 768px) {
        padding-top: calc(110rem/16);
        padding-bottom: calc(119rem/16);
    }
}
.profile__title {
    color: #fff;
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    margin-bottom: 1rem;
    font-size: calc(18rem /16);
    @media screen and (min-width: 768px) {
        font-size: calc(30rem /16);
    }
}

.profile__subtitle {
    color: #fff;
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    margin-bottom: .5rem;
    @media screen and (min-width: 768px) {
        font-size: calc(16rem /16);
        margin-bottom: 1rem;
    }
}

.profile__subtitle.profile__subtitle--big {
    @media screen and (min-width: 768px) {
        font-size: calc(20rem /16);
    }
}
.profile__link{
    color: var(--color-primary);
    text-decoration: underline;
    margin-top: 1rem;
    display: inline-block;
}
.profile-delete__btn{
    color: var(--color-primary)!important;
    text-decoration: underline!important;
    margin-top: 1rem;
    display: inline-block;
}
.profile-delete__btn:hover {
    text-decoration: none!important;
}

.profile-card__wrapper{
    position: relative;
    @media screen and (max-width: 767px) {
        margin: calc(40rem/16) 0;
    }
}

.profile-card__card{
    width: 100%;
    object-fit: contain;
}

.profile-card__content{
    position: absolute;
    inset: 0;
    padding: calc(93rem/16) calc(50rem/16) calc(35rem/16);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.profile-card__barcode-wrapper{
    width: 100%;
    background: white;
    position: relative;
}

.profile-card__barcode{
    position: absolute;
    inset: 0;
    object-fit: contain;
    width: 100%;
    height: 100%;
    padding: calc(8rem/16) calc(16rem/16) calc(16rem/16);
}

.profile-card__barcode-number {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: calc(12rem/16);
    padding: calc(4rem/16) calc(16rem/16);
    color: var(--color-text-default-dark);
    letter-spacing: calc(2rem/16);
    text-align: center;
}

.profile-card__title{
    text-transform: uppercase;
    font-size: calc(24rem/16);
    font-family: var(--font-default-bold);
    text-shadow: 0 2px 5px rgba(0,0,0,0.6);
}

.profile-card__subtitle{
    font-size: var(--font-size-default);
}

.profile-card__number-description{
    font-size: calc(12rem/16);
}

.profile__add-person-btn{
    border: 0.125rem dashed #d8d8d8;
    width: 100%;
    padding: calc(27rem/16) 0;
}

.profile__add-person-wrapper{
    border: 2px solid white;
    padding: calc(41rem/16) calc(41rem/16) calc(35rem/16);
}

.profile__delete-person-btn{
    border: none;
    background: transparent;
    color: var(--color-primary);
    display: inline-flex;
    align-items: center;
}

.profile__delete-person-text{
    text-decoration: underline;
}