.avalanche-item {
    background-color: var(--color-dark);
    padding: calc(60rem/16) calc(50rem/16) calc(60rem/16);
    height: 100%;
    font-size: calc(13rem/16);
    @media screen and (max-width: 767px) {
        height: auto;
        padding: calc(20rem/16) calc(20rem/16);
    }
}
.avalanche-item__title {
    font-family: var(--font-default-bold);
    line-height:1;
    text-transform:uppercase;
    margin-bottom: 0;
    @media screen and (max-width: 767px) {
        font-size: calc(18rem/16);
    }
}
.avalanche-item__sub-title {
    margin-top: calc(5rem/16);
    text-transform: uppercase;
}
.avalanche-item__img {
    @media screen and (max-width: 767px) {
        max-width: calc(150rem/16);
        margin: calc(10rem/16) auto;
        display: block;
    }
}