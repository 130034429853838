.embed-responsive-img-content-teaser:before{
    padding-top: 113.9489%;
}
.embed-responsive-img-content-teaser--logo:before{
    padding-top: 76.59%;
}
.embed-responsive-img-content-teaser--small:before{
    padding-top: calc(522 / 372 * 100%);
}
.img-content-teaser {
    height: 100%;
}
.img-content-teaser.img-teaser:not(.img-content-teaser--logo):hover .img-teaser__media {
     transform: translateX(-50%) scale(1.1);
}
.img-content-teaser--logo {
    background-color:#fff;
}
.img-content-teaser .img-teaser__content{
    @media screen and (min-width: 1200px){
        padding: calc(30rem/16);
    }
    padding: calc(15rem/16);
}
.img-content-teaser--small .img-teaser__content {
    @media screen and (min-width: 1200px){
        padding: calc(30rem/16) calc(30rem/16) 0 calc(30rem/16);
        margin-top: calc(25rem/16);
    }
    padding: calc(15rem/16) calc(15rem/16) 0 calc(15rem/16);
    margin-top: calc(15rem/16);
}
.img-content-teaser--logo .img-teaser__content {
    position: relative;
    height: auto;
}
.img-content-teaser__title{
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    font-size: calc(24rem/16);

    @media screen and (min-width: 768px) and (max-width: 1199px){
        font-size: calc(21rem/16);
    }

}

.img-content-teaser__sub-title{
    font-family: var(--font-default-bold);
    font-size: calc(16rem/16);
    @media screen and (max-width: 1599px){
        font-size: calc(15rem/16);
        line-height: 1.1;
    }
    @media screen and (max-width: 1199px) and (min-width: 768px){
        font-size: calc(14rem/16);
        line-height: 1.1;
    }
}
.img-content-teaser .btn {
    @media screen and (max-width: 1199px) and (min-width: 768px){
        padding: calc(5rem/16) calc(10rem/16);
    }
}
.img-content-teaser__region-logo {
    position: absolute;
    z-index: 3;
    right: calc(30rem/16);
    top: calc(30rem/16);
    @media screen and (max-width: 1599px) {
        right: calc(10rem/16);
        top: calc(10rem/16);
        max-width: calc(100rem/16);
    }
}