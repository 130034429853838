.consent{
    background: var(--color-grey);
}
.consent.consent--pos-absolute {
    position: absolute;
    left: 0;
    top: 0;
}
.consent__overlay{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: calc(16rem/16);
    font-size: var(--font-size-default);
    padding: calc(16rem/16);

    @media (min-width: 768px) {
        padding: 0;
    }
}
