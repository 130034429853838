@font-face {
    font-family: 'Montserrat-Bold';
    src: url('/static/fonts/Montserrat-Bold.woff2') format('woff2'),
    url('/static/fonts/Montserrat-Bold.woff') format('woff');
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat-Regular';
    src: url('/static/fonts/Montserrat-Regular.woff2') format('woff2'),
    url('/static/fonts/Montserrat-Regular.woff') format('woff');
    font-weight: bold;
    font-display: swap;
}

@font-face {
    font-family: 'Authenia';
    src: url('/static/fonts/Authenia.woff2') format('woff2'),
    url('/static/fonts/Authenia.woff') format('woff');
    font-weight: bold;
    font-display: swap;
}
