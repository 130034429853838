.status {
    width: calc(12rem/16);
    height: calc(12rem/16);
    border-radius: 50%;
    display: inline-block;
}
.status-success,
.status-open {
    background-color: var(--color-success);
}
.status-danger,
.status-closed,
.status-moderate {
    background-color: var(--color-danger);
}
.status-easy {
    background-color: #0067b5;
}
.status-difficult {
    background-color: #000;
}
.status-easy, .status-difficult, .status-moderate, .trail-status-easy, .trail-status-moderate, .trail-status-difficult {
    box-shadow: 0 0 0 calc(2rem/16) #ffffff;
}

/*_________ trail-status-colors___________*/
.trail-status-easy {
    background-color: #51b0d7;
}
.trail-status-moderate {
    background-color: #f08482;
}
.trail-status-difficult {
    background-color: #1d1d1b;
}