.quote-area__quote {
    font-family: var(--font-decorative);
    font-size: calc(25rem/16);
    line-height: calc(83/70);
    text-align: right;
    @media screen and (min-width: 768px) {
        font-size: responsive 25px 70px;
        font-range: 768px 1600px;
    }
}
.quote-area__footer {
    font-size: calc(20rem/16);
    text-transform: uppercase;
    text-align: center;
    @media screen and (max-width: 767px) {
        font-size: calc(14rem/16);
    }
}
.quote-area__inner {
    margin: 0 auto;
    max-width: calc(750rem/16);
    transform: rotate(-10deg);
    text-align: right;
    padding-left: 9%;
    padding-top: calc(40rem/16);
}
.quote-area__triangles {
    position: absolute;
    z-index: -1;
    height: 100%;
    width: 100%;
    left: -10%;
    top:0;
}