.hero-blog__img{
    height: 60vh;
    background-size: cover;
    background-position: center;
}
.hero-blog__top-title {
    color: var(--color-primary);
    font-size: calc(40rem/16);
    line-height: calc(49/40);
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    text-shadow: 0 2px 4px rgba(0,0,0,0.5);
    @media screen and (max-width: 767px) {
        font-size: calc(20rem/16);
    }
}
.hero-blog__date {
    margin-bottom: calc(18rem/16);
    text-shadow: 0 2px 4px rgba(0,0,0,0.5);
    font-size: calc(26rem/16);
    line-height:1;
    @media screen and (max-width: 767px) {
        font-size: calc(18rem/16);
    }
}
.hero-blog__content {
    bottom: 6%;
}
.hero__blog-author-info {
    z-index: 3;
    position: relative;
    margin-top: calc(30rem/16);
    text-align: center;
    @media screen and (max-width: 767px) {
        margin-top: calc(50rem/16);
    }
}
.hero__blog-author-info__title {
    font-size: calc(20rem/16);
    line-height: calc(24/20);
    margin-bottom: calc(25rem/16);
    text-shadow: 0 0 10px 0 rgba(0,0,0,0.5);
}
.hero__blog-author-info {
    color: var(--color-text-default);
}