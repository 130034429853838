.hero__region-indicator {
    width: 100%;
    position: absolute;
    z-index: 9;
    @media screen and (max-width: 767px) {
        max-width: calc(180rem/16);
        left: 0;
        right: 0;
        margin: 0 auto;
    }
}
.hero__region-indicator__triangle {
    position: absolute;
    transition: transform 0.1s ease;
    z-index: 2;
}
.hero__region-indicator__triangle1 {
    position: absolute;
    margin: 0 auto;
    width: 36%;
    left: calc(55rem/16);
    right: 0;
    z-index: 2;
}
.hero__region-indicator__triangle2 {
    top: 0%;
    left: 0;
    width: 33%;
}
.hero__region-indicator__triangle3 {
    right: 0;
    width: 55%;
}
.hero__region-indicator__author {
    position: absolute;
    width: 100%;
}
.hero__region-indicator__author .triangle-img__decorator {
    width: 150%;
    transform: translateX(-50%);
    left:50%;
    position: absolute;
    @media screen and (max-width: 1199px) {
        width: 100%;
    }
    @media screen and (max-width: 767px) {
        width: 80%;
    }
}
.hero__region-indicator__author .triangle-img__media-wrapper {
    margin-top: 20%;
}
.hero__region-indicator__wrapper{
    position: relative;
    height: 100%;
}
@media screen and (min-width: 768px) {
    .hero__region-indicator__triangle2 polygon {
        fill: transparent;
        transform: translateY(40px);
    }
    .hero__region-indicator__triangle1 polygon {
        fill: transparent;
        opacity:0.5;
        transform: translateY(40px);
    }
    .hero__region-indicator__triangle3 path {
        fill: transparent;
        transform: translateY(40px) rotate3d(20, 0, 0, 10deg);
    }
    .is-in-viewport.hero--full-height .hero__region-indicator__triangle2 polygon {
        animation: triangle 0.6s ease-in-out forwards 1.2s;
    }
    .is-in-viewport.hero--full-height .hero__region-indicator__triangle3 path {
        animation: triangle 0.6s ease-in-out forwards 1s;
    }
    .is-in-viewport.hero--full-height .hero__region-indicator__triangle1 polygon {
        animation: triangleRed 0.6s ease-in-out forwards 0.8s;
    }
    .is-in-viewport.hero-portal .hero__region-indicator__triangle2 polygon {
        animation: triangle 0.6s ease-in-out forwards 2.2s;
    }
    .is-in-viewport.hero-portal .hero__region-indicator__triangle3 path {
        animation: triangle 0.6s ease-in-out forwards 2s;
    }
    .is-in-viewport.hero-portal .hero__region-indicator__triangle1 polygon {
        animation: triangleRed 0.6s ease-in-out forwards 1.8s;
    }
}

@media screen and (max-width: 767px) {
    .hero__region-indicator--outer {
        fill: var(--color-text-default);
    }
    .hero__region-indicator--main {
        fill: var(--color-primary);
    }
}