.facility-item {
    background-color: var(--color-facility-bg);
    padding: calc(80rem/16) calc(40rem/16);
    text-align: center;
    height: 100%;
    @media screen and (min-width:768px) and (max-width: 1499px) {
        padding: calc(60rem/16) calc(10rem/16);
    }
    @media screen and (max-width: 767px) {
        padding: calc(30rem/16) calc(10rem/16);
        height: auto;
    }
}
.facility-item.facility-item__border--right:after{
    @media screen and (min-width:768px){
        position: absolute;
        content: "";
        height: 50%;
        width: calc(2rem/16);
        background-color:var(--color-default-bg);
        right: 0;
        top: 25%;
    }
    @media screen and (max-width:767px){
        position: absolute;
        content: "";
        width: 50%;
        height: calc(2rem/16);
        background-color:var(--color-default-bg);
        left: 25%;
        bottom: calc(1rem/-16);
    }
}
.facility-item.facility-item__border--bottom{
    @media screen and (min-width:768px){
        padding-bottom: 0;
    }
}
.facility-item.facility-item__border--bottom:after{
   position: absolute;
   content: "";
   width: 50%;
   height: calc(2rem/16);
   background-color:var(--color-default-bg);
   left: 25%;
   bottom: calc(41rem/-16);
   z-index: 2;
}
.facility-item__title {
    font-family: var(--font-default-bold);
    line-height:1;
    text-transform:uppercase;
    margin-bottom: 0;
    @media screen and (max-width: 1499px) {
        font-size: calc(18rem/16);
    }
}
.facility-item__icon {
    font-size: calc(100rem/16);
    display: block;
    margin: calc(50rem/16) 0;
    @media screen and (max-width: 1499px) {
        font-size: calc(60rem/16);
    }
    @media screen and (max-width: 768px) {
        margin: calc(25rem/16) 0;
    }
}
.facility-item__value {
    font-size: calc(40rem/16);
    line-height: calc(49/40);
    font-family: var(--font-default-bold);
    @media screen and (max-width: 1499px) {
        font-size: calc(28rem/16);
    }
}
.facility-item__value-label {
    text-transform: uppercase;
    font-size: calc(16rem/16);
}
.facility-item__status {
    margin-top: .5rem;
}