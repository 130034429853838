.tour-teaser{
    @media screen and (min-width: 768px) and (max-width: 1200px){
       font-size: calc(15rem/16);
    }
    
    @media screen and (min-width: 768px){
       height: 100%;
    }
}
.tour-teaser__top-title{
    text-transform: uppercase;
    margin-bottom: calc(10rem/16);
}

.tour-teaser__title{
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    margin-bottom: calc(20rem/16);
}

.tour-teaser__data{
    color: var(--color-text-default);
    @media screen and (min-width: 768px) and (max-width: 1100px){
       font-size: calc(14rem/16);
    }
}

.tour-teaser__data__icon-text{
    width: calc(95rem/16);
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    
    @media screen and (min-width: 768px) and (max-width: 1100px){
       width: calc(80rem/16);
    }
}

.tour-teaser__data__icon-text .icon{
    margin-top: calc(2rem/16);
}
@media screen and (min-width: 768px) {
    .tour-teaser__media {
        transition: transform 0.4s cubic-bezier(0.7, 0, 0.4, 1);
    }
    .tour-teaser:hover .tour-teaser__media {
        transform: scale(1.1) translateX(-50%);
    }
}