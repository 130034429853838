.img-teaser__embed--portrait {
    padding-top: calc(580 / 480 * 100%);
    @media screen and (max-width: 767px) {
        padding-top: 100%;
    }
}
.img-teaser__embed--landscape {
    padding-top: calc(580 / 960 * 100%);
    @media screen and (max-width: 767px) {
        padding-top: 100%;
    }
}

.img-teaser {
    position: relative;
}
.img-teaser__additional-img {
    max-height: calc(215rem/16);
    @media screen and (max-width: 1100px) {
        max-height: calc(100rem/16);
    }
}
.img-teaser:before {
    content:'';
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    z-index: 1;
    opacity: 0.6;
    transition: opacity 0.6s cubic-bezier(0.7, 0, 0.3, 1);
    background: linear-gradient(180deg, rgba(61,61,61,0) 0%, var(--color-default-bg) 100%);
}
.img-teaser__content {
    position: absolute;
    z-index: 2;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    padding: calc(20rem/16);
    display: flex;
    justify-content:flex-end;
    flex-direction: column;
    @media screen and (min-width: 1200px){
        padding: calc(40rem/16);
    }
}
.img-teaser--landscape .img-teaser__content {
    @media screen and (min-width: 768px) {
        max-width: 80%;
    }

    @media screen and (min-width: 1200px){
        max-width: 60%;
    }
}
.img-teaser--portrait .img-teaser__content {
    @media screen and (min-width: 768px) {
        max-width: 90%;
    }
}
.img-teaser__sub-title {
    font-family: var(--font-headline);
    text-transform: uppercase;
    margin-bottom: calc(5rem/16);
    @media screen and (max-width: 1499px) and (min-width: 768px) {
        font-size: calc(14rem/16);
        line-height:1;
    }
    @media screen and (max-width: 767px) {
        font-size: calc(11rem/16);
    }
}
.img-teaser__title {
    font-family: var(--font-headline);
    line-height:1;
    font-size: calc(24rem/16);
    text-transform: uppercase;
    @media screen and (max-width: 1499px) and (min-width: 1200px) {
        font-size: calc(18rem/16);
    }
    @media screen and (max-width: 1199px) and (min-width: 768px){
        font-size: calc(15rem/16);
    }
    @media screen and (max-width: 767px) {
        font-size: calc(14rem/16);
        hyphens: auto;
    }
}
@media screen and (min-width: 768px) {
    .img-teaser__media {
        transition: transform 0.6s cubic-bezier(0.7, 0, 0.4, 1);
    }
    .img-teaser:hover .img-teaser__media {
        transform: scale(1.1);
    }
    .img-teaser:hover:before {
        opacity:0.3;
    }
}