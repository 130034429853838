.badge-teaser{
    max-width: calc(430rem/16);
    text-align: center;
    margin: 0 auto;
}

.badge-teaser__img{
    width: calc(250rem/16);
    margin: 0 auto;
}

.badge-teaser__body{
    padding: calc(15rem/16) calc(30rem/16);
}

.badge-teaser__title{
    font-family: var(--font-default-bold);
    margin-bottom: calc(15rem/16);
    text-transform: uppercase;
}

/* Slider */

.badge-teaser-slider .slider-arrow--outlines{
    position: absolute;
    top: 50%;
    z-index: 2;
}

.badge-teaser-slider .slider-arrow--outlines.arrow-prev{
    transform: translateY(-50%);
    left: calc(-10rem/16);
    @media screen and (min-width: 768px){
        transform: translateY(-50%) translateX(-100%);
        left: calc(-50rem/16);
    }
}

.badge-teaser-slider .slider-arrow--outlines.arrow-next{
    transform: translateY(-50%);
    right: calc(-10rem/16);

    @media screen and (min-width: 768px){
        transform: translateY(-50%) translateX(100%);
        right: calc(-50rem/16);
    }
}

.badge-teaser-slider .slider-arrow--outlines.slick-disabled{
    opacity: 0.2;
    cursor: auto;
}
