.breadcrumb-item+.breadcrumb-item:before {
    font-size: calc(12rem/16);
    content: var(--icon-arrow-right);
    font-family: iconfont;
    speak: none;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate(0);
    transform: translate(0);
}
.breadcrumb {
    line-height:1;
    padding-bottom: 1.5rem;
    border-bottom: .0625rem solid var(--color-text-default);
}
.breadcrumb-item.active {
    font-family: var(--font-default-bold);
    color: var(--color-text-default);
}