.wysiwyg > p,
.wysiwyg > ol,
.wysiwyg > ul,
.wysiwyg > table {
    margin-bottom: 2em;
}
.wysiwyg > p:last-child,
.wysiwyg > ol:last-child,
.wysiwyg > ul:last-child,
.wysiwyg > table:last-child {
    margin-bottom: 0;
}
.wysiwyg img {
    max-width: 100%;
    height: auto !important; /*override inline style from wysiwyg*/
}
.wysiwyg a:not(.btn) {
    color: var(--color-text-default);
    text-decoration: underline;
}
.wysiwyg a:hover,
.wysiwyg a:focus,
.wysiwyg a:active {
    color: var(--color-primary);
    text-decoration: underline;
}
.wysiwyg a.btn:hover {
    color: var(--color-default);
    text-decoration: none;
}
.wysiwyg ul{
    padding-left: 0;
    list-style: none;
}

.wysiwyg li{
    position: relative;
    padding-left: calc(20rem/16);
}

.wysiwyg li+li{
    margin-top: calc(15rem/16);
}

.wysiwyg ul li:before{
    content: '';
    position: absolute;
    width: calc(8rem/16);
    height: calc(8rem/16);
    background-color: var(--color-primary);
    top: calc(7rem/16);
    left: 0;
}
.wysiwyg-area--centered {
    text-align: center;
}
.wysiwyg--two-cols-style .wysiwyg-area__content{
    @media screen and (min-width: 768px) {
        column-count: 2;
        column-gap: calc(30rem/16);
    }
}