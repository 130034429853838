.triangle-badge{
    position: relative;
}

.triangle-badge__icon{
    font-size: calc(36rem/16);
    color: var(--color-primary);

    @media screen and (min-width: 768px){
        font-size: calc(57rem/16);
    }
}

.triangle-badge.is-inclusive .triangle-badge__icon{
    color: #8CA619;
}

.triangle-badge.is-bonus .triangle-badge__icon{
    color: #1A5C91;
}

.triangle-badge__text{
    position: absolute;
    left: 50%;
    bottom: calc(5rem/16);
    transform: translateX(-50%);
    text-align: center;
    margin-left: calc(8rem/16);
    width: 100%;
    @media screen and (max-width: 767px) {
        font-size: 1rem;
    }
}
.triangle-badge__text--small {
    font-size: calc(10rem/16);
    display: block;
    line-height:1;

    @media screen and (min-width: 768px){
        font-size: calc(16rem/16);
    }
}
.triangle-badge.is-top{
    position: absolute;
    top: calc(-10rem/16);
    left: calc(-20rem/16);
    z-index: 1;
}
.triangle-badge.is-top--inline{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}
.triangle-badge.is-bottom{
    position: absolute;
    right: 0;
    bottom: calc(-15rem/16);
    z-index: 1;
}
.triangle-badge.is-bottom--inline{
    position: absolute;
    right: calc(8rem /16);
    bottom: 0;
    z-index: 1;
}
.triangle-badge__text{
    font-size: calc(20rem/16);
    color: #fff;
    text-shadow: 0 0 10px rgba(0,0,0,0.5);
    font-family: var(--font-default-bold);

    @media screen and (min-width: 768px){
        font-size: calc(26rem/16);
    }
}



.badge-text{
    padding: calc(2rem/16) calc(9rem/16);
}

.badge-text--easy{
    background-color: #009d26;
    color: #fff;
}

.badge-text--medium{
    background-color: #CE0505;
    color: #fff;
}

.badge-text--hard{
    background-color: #000000;
    color: #fff;
}