.gastronomy-teaser {
    background-color:#fff;
    border-radius: calc(10rem/16);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    color: var(--color-dark);
    box-shadow: 0 calc(40rem/16) calc(40rem/16) 0 rgba(0,0,0,0.2);
    @media screen and (min-width: 768px) {
        height: 100%;
    }
}
.gastronomy-teaser__body {
    padding: calc(25rem/16) calc(40rem/16);
    font-size: calc(16rem/16);
    display:flex;
    flex-direction: column;
    flex: 1 auto;
    @media screen and (max-width: 1199px) {
        padding: calc(25rem/16) calc(20rem/16);
    }
}
.gastronomy-teaser__title {
    font-family: var(--font-headline);
    line-height:1.1;
    font-size: calc(20rem/16);
}
.gastronomy-teaser__content {
    padding-top: calc(25rem/16);
}
.gastronomy-teaser__icon {
    font-size: calc(22rem/16);
    color: var(--color-primary);
}
.gastronomy-teaser__icon--small {
    font-size: calc(18rem/16);
}
.gastronomy-teaser__status {
    position: absolute;
    top:0;
    padding: calc(10rem/16);
    color:#fff;
    font-size: 1rem;
    text-shadow: 0 calc(2rem/16) calc(10rem/16) rgba(0, 0, 0, 0.55);
    z-index: 2;
}
.gastronomy-teaser__media-wrapper {
    position: relative;
}
.gastronomy-teaser__media-wrapper:before {
    background: linear-gradient(180deg, rgba(61,61,61,0) 0%, #3D3D3D 100%);
    content:'';
    position: absolute;
    width: 100%;
    height:100%;
    top:0;
    left:0;
    z-index: 1;
    display: block;
    opacity:0.5;
}
.gastronomy-teaser__sft-logo {
    position: absolute;
    right:0;
    top:0;
    font-size: calc(22rem/16);
    text-shadow: 0 calc(2rem/16) calc(4rem/16) #000000;
    color: var(--color-white);
    padding: calc(15rem/16);
}