.loading-overlay-container {
    position: relative;
    overflow: hidden;
}
.loading-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(48, 48, 48, 0.41);
    z-index: 3;
    text-align: center;
    opacity:0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items:center;
    animation: loading-opacity 0.2s ease-in-out both;
}
@keyframes loading-opacity {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}
