html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content in IE*/
}
body {
    position: relative;
    background-color: var(--color-default-bg);
    word-break: break-word;
    overflow-x: hidden;
}

body.nav-open{
    overflow: hidden;
}
@media screen and (max-width: 767px) {
    body {
        min-width: calc(320rem/16);
        max-width: 100%;
        overflow-x: hidden;
    }
}
.main-content__body {
    overflow: hidden;
    background-color: var(--color-default-bg);
    padding-top:  calc(70rem/16);
    position: relative;
    z-index: 1;
    @media screen and (max-width: 767px) {
        padding-top: calc(30rem/16);
    }
}
.main-content__body--portal {
    padding-top:  calc(20rem/16);
    overflow: hidden;
    @media screen and (max-width: 767px) {
        padding-top: calc(20rem/16);
    }
}
.main-content__body--no-space-top{
    padding-top: 0;
    overflow: hidden;
    @media screen and (max-width: 767px) {
        padding-top: 0;
    }
}

/* portal spacing for navigation incl nav-info text */
.main-content__spacing-portal--has-info {
    padding-top: var(--navbar-info-height);
    @media screen and (max-width: 767px) {
        padding-top: calc(var(--main-nav-height--xs) + var(--navbar-info-height--xs));
    }
}
/* portal spacing for navigation */
.main-content__spacing-portal {
    padding-top: 0; /* because nav is transparent */
    @media screen and (max-width: 767px) {
        padding-top: var(--main-nav-height--xs);
    }
}
/* content spacing for navigation */
.main-content__spacing {
    padding-top: var(--main-nav-height-spacing);
    @media screen and (max-width: 767px) {
        padding-top: var(--main-nav-height-spacing-xs);
    }
}

/* content spacing for navigation without subnav */
.main-content__spacing--without-subnav {
    padding-top: var(--main-nav-height);
    @media screen and (max-width: 767px) {
        padding-top: var(--main-nav-height--xs);
    }
}
/* content spacing for navigation incl nav-info text
.main-content__spacing--has-info {
    padding-top: calc(var(--main-nav-height-spacing) + var(--navbar-info-height));
    @media screen and (max-width: 767px) {
        padding-top: calc(var(--main-nav-height-spacing-xs) + var(--navbar-info-height--xs));
    }
}*/