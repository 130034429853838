.range-slider .noUi-target {
    background: var(--color-dark);
    border-radius: 0;
    border: none;
    box-shadow: none;
}

.range-slider .noUi-horizontal {
    height: calc(5rem/16);
}

.range-slider .noUi-connect{
    background: var(--color-primary);
}

.range-slider .noUi-horizontal .noUi-handle {
    width: calc(19rem/16);
    height: calc(19rem/16);
    border-radius: 50%;
    top: -6px;
    border: calc(2rem/16) solid #3D3D3D;
    box-shadow: none;
}

.range-slider .noUi-handle:after,
.range-slider .noUi-handle:before{
    display: none;
}

.range-slider__values{
    color: var(--color-light);
    margin-top: calc(10rem/16);
}