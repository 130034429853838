.nav-tabs-icon{
    justify-content: center;
    border: none;
    
    @media screen and (max-width: 767px){
       padding: 0;
    }
}

.nav-tabs-icon .nav-link{
    border: none;
    opacity: 0.5;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    transition: all 150ms ease;
}

.nav-tabs-icon__circle{
    width: calc(70rem/16);
    height: calc(70rem/16);
    border-radius: 50%;
    border: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(28rem/16);

    @media screen and (min-width: 767px) {
        width: calc(107rem/16);
        height: calc(107rem/16);
        font-size: calc(50rem/16);
    }
}

.nav-tabs-icon__circle .icon-slopelines {
    font-size: calc(20rem/16);

    @media screen and (min-width: 767px) {
        font-size: calc(35rem/16);
    }
}

.nav-tabs-icon .nav-link.active,
.nav-tabs-icon .nav-link:focus,
.nav-tabs-icon .nav-link:hover{
    opacity: 1;
}

.nav-tabs-icon__text{
    font-family: var(--font-default-bold);
    margin-top: calc(10rem/16);
    display: inline-block;
    font-size: calc(12rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(14rem/16);
    }
}
.nav-tabs-icon--big-spacing .nav-link {
    @media screen and (min-width: 768px) {
        padding-left: calc(35rem/16);
        padding-right: calc(35rem/16);
    }
}