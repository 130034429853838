.overlay {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    padding-top: 0;
    transform: translateX(100%);
    transition: transform 180ms ease-out, visibility 0.001ms 180ms, opacity 180ms ease;
    background: var(--color-secondary);
    z-index: 200;
    overflow: hidden;
    will-change: transform;
    visibility: hidden;
    opacity: 0;
    color: var(--color-text-default-dark);
}
.overlay.is-open {
    transform: translateX(0);
    visibility: visible;
    opacity: 1;
    transition: transform 180ms ease-out, visibility 0.001ms, opacity 180ms ease;
}
.overlay__header {
    height: calc(60rem/16);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.64);
    position: relative;
    z-index: 500;
    background-color: var(--color-secondary-dark);
    color:#fff;
    display: flex;
    align-items: center;
    font-size: 17px;
    flex: none;
}
.overlay__content {
    flex: auto;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}
.overlay__container {
    padding: calc(30rem/16) calc(10rem/16);
}
.overlay__footer {
    flex: none;
}
.overlay__container {
    width: 100%;
    margin: 0 auto;
    padding-left: calc(20rem / 16);
    padding-right: calc(20rem / 16);
}

.overlay label:not(.floating-label){
    color: #fff;
}