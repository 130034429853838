.world-teaser__embed-big {
    padding-top: calc(58 / 96 * 100%);
    @media screen and (max-width: 767px) {
        padding-top: 100%;
    }
}
.world-teaser__embed {
    padding-top: calc(58 / 64 * 100%);
    @media screen and (max-width: 767px) {
        padding-top: 100%;
    }
}

.world-teaser {
    cursor: pointer;
    position: relative;
}
.world-teaser__title {
    font-size: calc(30rem/16);
    line-height: 1;
    text-shadow: 0 calc(2rem/16) calc(40rem/16) #000000;
    text-transform: uppercase;
    font-family: var(--font-headline);
    @media screen and (min-width: 768px) {
        font-size: responsive 18px 30px;
        font-range: 768px 1600px;
    }
    @media screen and (max-width: 767px) {
        font-size: calc(20rem/16);
    }
}
.world-teaser:before {
    content:'';
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    z-index: 1;
    opacity: 0.6;
    transition: opacity 0.6s cubic-bezier(0.7, 0, 0.3, 1);
    background: linear-gradient(180deg, rgba(61,61,61,0) 0%, #3D3D3D 100%);
}
.world-teaser__content {
    position: absolute;
    z-index: 2;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    padding: calc(40rem/16);
    display: flex;
    justify-content:center;
    flex-direction: column;
    text-align: center;
    @media screen and (max-width: 1499px) {
        padding: calc(20rem/16);
    }
}
.world-teaser--with-buttons .world-teaser__content {
    justify-content: flex-end;
    padding: calc(40rem/16) calc(40rem/16) calc(100rem/16);
    @media screen and (max-width: 1499px) and (min-width: 768px) {
        padding: calc(20rem/16) calc(10rem/16);
    }
    @media screen and (max-width: 767px) {
        padding: calc(10rem/16);
    }
}
.world-teaser__btn-wrapper {
    justify-content: center;
    margin-top: calc(120rem/16);
    @media screen and (max-width: 1499px) {
        margin-top: calc(20rem/16);
    }
}
.world-teaser__btn {
    opacity: 0.8;
    margin-top: calc(10rem/16);
    @media screen and (max-width: 767px) {
        padding: calc(10rem/16);
        font-size: calc(12rem/16);
    }
}
.world-teaser__webcam-info {
    position: absolute;
    top:0;
    left:0;
    display: flex;
    align-items: center;
    z-index: 2;
}
.world-teaser__webcam-info__badge {
    background-color: #3D3D3D;
    color:#fff;
    text-transform: uppercase;
    padding: calc(5rem/16) calc(20rem/16);
    display: flex;
    align-items:center;
}
.world-teaser__webcam-info__badge-status {
    width: calc(15rem/16);
    height:calc(15rem/16);
    background: red;
    display: inline-block;
    border-radius: 50%;
    margin-left: calc(7rem/16);
}
.world-teaser__webcam-info__title {
    font-size: calc(12rem/16);
    text-transform: uppercase;
    text-shadow: 0 0.125rem 2.5rem #000;
    font-family: var(--font-default-bold);
    padding-left: calc(10rem/16);
}

@media screen and (min-width: 768px) {
    .world-teaser__media {
        transition: transform 0.6s cubic-bezier(0.7, 0, 0.4, 1);
    }
    .world-teaser:hover .world-teaser__media {
        transform: scale(1.1);
    }
    .world-teaser:hover:before {
        opacity:0.3;
    }
}