.contact-teaser {
    position: relative;
    background-color: var(--color-dark);
    overflow: hidden;
    @media screen and (min-width: 768px) {
        height: 100%;
    }
}
/* needs to be same as icon-teaser:before */
.contact-teaser:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    left:0;
    border-width: calc(270rem/16) calc(270rem/16) 0 0;
    border-color: var(--color-dark-highlight) transparent transparent transparent;
    @media screen and (min-width: 1400px){
        border-width: calc(385rem/16) calc(496rem/16) 0 0;
    }
    @media screen and (min-width: 1100px){
        border-width: calc(258rem/16) calc(402rem/16) 0 0;

    }
}
.contact-teaser__media-wrapper {
    max-width: calc(108rem/16);
    margin: 0 auto calc(20rem/16);
    border-radius: 50%;
}
.contact-teaser__icon {
    color: var(--color-light-blue);
    font-size: calc(60rem/16);
    margin: 0 auto calc(40rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(40rem/16);
        margin: 0 auto calc(20rem/16);
    }
}
.contact-teaser__body {
    display: flex;
    flex-direction: column;
    z-index: 4;
    position: relative;
    width: 100%;
    padding: calc(60rem/16) calc(40rem/16);
    @media screen and (max-width: 767px) {
        padding: calc(30rem/16) calc(20rem/16);
    }
}
.contact-teaser__title {
    font-family: var(--font-headline);
    text-transform: uppercase;
    text-align: center;
    margin-bottom: calc(40rem/16);
}
.contact-teaser a:hover {
    color: var(--color-primary);
}
.contact-teaser__link {
    font-family: var(--font-default-bold);
    display: block;
    margin-top: calc(20rem/16);
}
.contact-teaser__link-icon {
    font-size: calc(22rem/16);
}