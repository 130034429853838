.table-facilities{
    background-color: var(--color-facility-bg);
}

.table.table-facilities thead th,
.wysiwyg table.table-facilities thead th{
    background-color: var(--color-facility-bg);
}

.table.table-facilities td,
.table.table-facilities th{
    border-color: var(--color-default-bg);
}
.wysiwyg table.table-facilities thead th{
    border-bottom: 0;
    border-top: 0;
    font-family: var(--font-default);
}

.table-facilities__lift-icon{
    font-size: calc(33rem/16);
}

.table-facilities__webcam-icon{
    font-size: calc(26rem/16);
}

table.table-facilities td{
    vertical-align: middle;
}
