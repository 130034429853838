.real-card-teaser {
    background-color: var(--color-dark-highlight);
    padding: calc(35rem/16);
    position: relative;
}
.real-card-teaser__img {
    transform: rotate(-22deg);
    position: absolute;
    margin-top: calc(-50rem/16);
    margin-left: calc(-80rem/16);
    @media screen and (max-width: 1499px) and (min-width: 1300px) {
        width: calc(150rem/16);
        margin-top: calc(-25rem/16);
        margin-left: calc(-35rem/16);
    }
    @media screen and (max-width: 1299px) and (min-width: 768px) {
        width: calc(130rem/16);
        margin-top: calc(-25rem/16);
        margin-left: calc(-35rem/16);
    }
    @media screen and (max-width: 767px) {
        position: relative;
        width: calc(164rem/16);
        margin: 0 auto;
        margin-bottom: calc(40rem/16);
        transform: rotate(-10deg);
        display: block;
    }
}
.real-card-teaser__content {
    margin-left: calc(150rem/16);
    @media screen and (max-width: 767px) {
        margin-left:0;
    }
}
.real-card-teaser__title {
    line-height: calc(24/20);
    font-family: var(--font-headline);
    text-transform: uppercase;
    margin-bottom: calc(20rem/16);
    font-size: calc(18rem/16);
    @media screen and (min-width: 768px){
        font-size: calc(16rem/16);
    }
    @media screen and (min-width: 1400px){
        font-size: calc(20rem/16);
    }
}