.simple-img-teaser {
    cursor: pointer;
    position: relative;
}
.simple-img-teaser__title {
    font-size: calc(40rem/16);
    line-height: 1;
    text-shadow: 0 0.125rem 10px #00000063;
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    @media screen and (max-width: 767px) {
        font-size: calc(28rem/16);
    }
}
.simple-img-teaser--small .simple-img-teaser__title {
    font-size: calc(20rem/16);
}
.simple-img-teaser:before {
    content:'';
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    z-index: 1;
    opacity: 0.5;
    transition: opacity 0.3s cubic-bezier(0.7, 0, 0.3, 1);
    background: linear-gradient(180.75deg, rgba(61,61,61,0) 0%, var(--color-default-bg) 100%);
}
.simple-img-teaser__body {
    position: absolute;
    z-index: 2;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    padding: calc(40rem/16);
    display: flex;
    justify-content:center;
    flex-direction: column;
    text-align: center;
    @media screen and (max-width: 767px) {
        padding: calc(20rem/16);
    }
}

@media screen and (min-width: 768px) {
    .simple-img-teaser__media {
        transition: transform 0.3s cubic-bezier(0.4, 0, 0.6, 1);
    }
    .simple-img-teaser:hover .simple-img-teaser__media {
        transform: scale(1.1);
    }
    .simple-img-teaser:hover:before {
        opacity:0.3;
    }
}