.hero__live-infos {
    max-width: calc(350rem/16);
    margin-left: auto;
    @media screen and (max-width: 767px) {
        max-width: 100%;
    }
}
.hero__live-infos {
    padding-bottom: calc(65rem/16);
    height: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    @media screen and (max-width: 767px) {
        padding-bottom: calc(0rem/16);
    }
}

.hero-small .hero__live-infos{
    padding-bottom: 0;
}

.hero__live-infos__item-img {
    width: calc(70rem/16);
    @media screen and (max-width: 767px) {
        width: calc(50rem/16);
    }
}
.hero__live-infos__item-text {
    font-size: calc(22rem/16);
    font-family: var(--font-default-bold);
    text-align: center;
    line-height: calc(30/22);
    margin-top: calc(19rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(16rem/16);
    }
}
.hero__live-infos__item-icon {
    font-size: calc(50rem/16);
    display: block;
    margin: 0 auto;
    text-align: center;
    transition: transform 0.2s ease;
    @media screen and (max-width: 767px) {
        font-size: calc(60rem/16);
        display: flex;
        flex-direction: column;
    }
}
@media screen and (min-width: 768px) {
    .hero__live-infos__item:hover .hero__live-infos__item-icon {
        transform: scale(1.05);
    }
}