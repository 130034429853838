:root {
    --color-primary: #E10632;  /* already changed */
    --color-secondary: #3D3D3D; /* already changed */
    --color-text-default: #FFFFFF;  /* already changed */
    --color-text-default-dark: #3D3D3D; /* already changed */
    --color-text-muted: #949494; /* already changed */
    --color-dark-highlight:  #262626; /* is used for widgets */
    --color-dark-highlight--light: #333333; /* is used for widgets */
    --color-default-bg: #3D3D3D;
    --color-lesachtal:#17912A;
    --color-weissensee:#00B8C5;
    --color-friaul: #FED330;

    --color-icon-teaser-text: var(--color-dark);
    --color-default: #3D3D3D; /* already changed */
    --color-white: #FFF;
    --color-light: #949494; /* already changed */
    --color-dark-grey: #262626;/* already changed */
    --color-dark: #1D1D1D;  /* already changed */
    --color-light-blue: #30AFF8;
    --color-grey: #949494;
    --color-form-elements: #949494;
    --color-footer-headlines: #949494;
    --color-subnav-bg: var(--color-secondary);
    --color-subnav-button-bg: var(--color-light);
    --color-subnav-button-color: #fff;

    --color-success: #A8F63B;/* already changed */
    --color-info: #FFC107;
    --color-warning: #ffc107;
    --color-info-blue: #004985;
    --color-danger: #DC3545;

    --color-black:#000;
    --color-primary-contrast: #FFF;
    --color-secondary-contrast: #FFF;
    --color-success-contrast: #FFF;
    --color-info-contrast: var(--color-text-default);
    --color-warning-contrast: var(--color-text-default);
    --color-danger-contrast: #FFF;
    --color-light-contrast: var(--color-text-default-dark); /* already changed */
    --color-dark-contrast: #FFF;
    --color-white-contrast: var(--color-text-default-dark); /* already changed */
    --color-default-contrast: #FFF;

    --color-primary-dark: #a10524; /* already changed */
    --color-secondary-dark: #2d2d2d; /* already changed */
    --color-success-dark: #12862B;
    --color-info-dark: #967102;
    --color-warning-dark: #967102;
    --color-danger-dark: #cc2333;
    --color-light-dark: #e4e8ec;
    --color-dark-dark: #14191E;
    --color-white-dark: #b4b4b6; /* already changed */
    --color-default-dark: #1f232a;

    --color-primary-light: #fc4048; /* already changed */
    --color-secondary-light: #707070; /* already changed */
    --color-success-light: #1EE048;
    --color-info-light: #FBBD03;
    --color-warning-light: #FBBD03;
    --color-danger-light: #e15260;
    --color-light-light: #fff;
    --color-dark-light: #687F96;
    --color-white-light: #FFF;
    --color-default-light: #3b4552;
    --color-nav-tab-active: #fff;
    --color-facility-bg: var(--color-dark);

    --font-default: "Montserrat-Regular", sans-serif;
    --font-default-bold: "Montserrat-Bold", sans-serif;
    --font-headline: "Montserrat-Bold", sans-serif;
    --font-decorative: "Authenia", sans-serif;

    --font-size-default: 1rem;
    --font-size-default--mobile: calc(14rem/16);

    --main-nav-height: calc(134rem/16);
    --main-nav-height--xs: calc(65rem/16);

    /* main-nav-height + 60px for sub-world-navigation */
    --main-nav-height-spacing: calc(var(--main-nav-height) + 60rem/16);
    --main-nav-height-spacing-xs: var(--main-nav-height--xs);

    --font-default-weight: 300;
    --gutter: 2.375rem;
}
