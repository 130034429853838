.nav-dropdown{

    left: 0;
    right: 0;
    background-color: #fff;
    color: var(--color-text-default-dark);
    z-index: 10;

    @media screen and (min-width: 768px){
        position: absolute;
        padding: calc(80rem/16) calc(16rem/16);
        opacity: 0;
        pointer-events: none;
        visibility: hidden;
        transition: opacity 300ms ease, visibility 300ms ease;
        transition-delay: 150ms;

    }

    @media screen and (max-width: 767px){
        top: 0;
    }
}

.nav-dropdown--content-visibility{

    left: 0;
    right: 0;
    background-color: #fff;
    color: var(--color-text-default-dark);
    z-index: 10;

    @media screen and (min-width: 768px){
        position: absolute;
        padding: calc(80rem/16) calc(16rem/16);
        opacity: 0;
        pointer-events: none;
        content-visibility: hidden;
        transition: opacity 300ms ease, content-visibility 300ms allow-discrete;
        transition-delay: 150ms;

    }

    @media screen and (max-width: 767px){
        top: 0;
    }
}

.subnav__nav .nav-item.active .nav-dropdown{
    transform: translateX(0);
}

.main-nav>.navbar-nav>.nav-item.is-open .nav-dropdown,
.subnav__nav .nav-item.is-open .nav-dropdown{
    @media screen and (min-width: 768px){
        opacity: 1;
        visibility: visible;
        pointer-events: initial;
        transition: opacity 300ms ease, visibility 150ms ease;
    }
}

.main-nav>.navbar-nav>.nav-item.is-open .nav-dropdown--content-visibility,
.subnav__nav .nav-item.is-open .nav-dropdown--content-visibility{
    @media screen and (min-width: 768px){
        opacity: 1;
        content-visibility: auto;
        pointer-events: initial;
        transition: opacity 300ms ease, content-visibility 300ms allow-discrete;
    }
}


.nav-dropdown__list-container{
    @media screen and (min-width: 768px){
       width: calc(550rem/16);
    }
    
    @media screen and (max-width: 767px){
       width: 100%;
    }
}

.nav-dropdown__teaser-container{
    @media screen and (min-width: 768px){
       width: calc(370rem/16);
    }
}




.nav-dropdown__list{
    position: relative;

    @media screen and (min-width: 768px){
        display: inline-block;
        width: 65%;
    }

    @media screen and (max-width: 767px){
       width: 100%;
        margin-top: calc(40rem/16);
    }
}


.nav-dropdown-container{
    width: 100%;
    max-width: calc(1320rem/16);
}

.nav-dropdown__hl{
    font-size: calc(20rem/16);
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    margin-bottom: calc(20rem/16);
}
.nav-dropdown__link {
    @media screen and (min-width: 768px) {
        padding-right: calc(45rem/16);
    }
}
.nav-dropdown__item+.nav-dropdown__item{
    @media screen and (min-width: 768px){
        margin-top: calc(35rem/16);
    }

}

.nav-dropdown__link{
    font-size: calc(20rem/16);
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    color: var(--color-text-default-dark);

    @media screen and (min-width: 768px){
        color: var(--color-form-elements);
    }

    @media screen and (max-width: 767px){
        padding: calc(8rem/16) calc(20rem/16);
        padding-right: calc(50rem/16);
        display: block;
    }
}
.nav-dropdown__item.active .nav-dropdown__link {
    color: var(--color-text-default-dark);
}

.nav-dropdown__link:hover{
    @media screen and (min-width: 768px){
        color: var(--color-form-elements);
    }
}
 
.nav-dropdown__item.active>a{
    color: var(--color-text-default-dark);
}

.nav-dropdown-sub__list{

    @media screen and (min-width: 768px){
        position: absolute;
        right: 0;
        transform: translateX(100%);
        top: calc(5rem/16);
        visibility: hidden;
        opacity: 0;
        max-width: calc(200rem/16);
        color: var(--color-grey);
        transition: opacity 300ms ease, visibility 300ms ease;
    }

    @media screen and (max-width: 767px){
        height: 0;
        overflow: hidden;
        transition: all 250ms ease-in-out;
        padding-left: calc(30rem/16);
        margin-top: calc(8rem/16);
        margin-bottom: 5px;
        color: var(--color-grey);
    }
}

.nav-dropdown-sub__list--content-visibility{

    @media screen and (min-width: 768px){
        position: absolute;
        right: 0;
        transform: translateX(100%);
        top: calc(5rem/16);
        content-visibility: hidden;
        opacity: 0;
        max-width: calc(200rem/16);
        color: var(--color-grey);
        transition: opacity 300ms ease, content-visibility 300ms allow-discrete;
    }

    @media screen and (max-width: 767px){
        height: 0;
        overflow: hidden;
        transition: all 250ms ease-in-out;
        padding-left: calc(30rem/16);
        margin-top: calc(8rem/16);
        margin-bottom: 5px;
        color: var(--color-grey);
    }
}

.nav-dropdown__item.is-open > .nav-dropdown-sub__list{
    @media screen and (min-width: 768px){
        opacity: 1;
        visibility: visible;
        transition: opacity 300ms ease, visibility 150ms ease;
    }
}

.nav-dropdown__item.is-open > .nav-dropdown-sub__list--content-visibility{
    @media screen and (min-width: 768px){
        opacity: 1;
        content-visibility: auto;
        transition: opacity 300ms ease, content-visibility 300ms allow-discrete;
    }
}

.nav-dropdown__item.active .nav-dropdown-sub__list{
    height: auto;
}

.nav-dropdown__item.is-open .nav-dropdown__link{
    color: var(--color-text-default-dark);
}

.nav-dropdown__item__line-wrapper{
    position: relative;
    padding-right: calc(48rem/16);
}

.nav-dropdown__item__line-wrapper:before{
    @media screen and (min-width: 768px){
        content: var(--icon-arrow-down-bold);
        position: absolute;
        font-size: calc(11rem /16);
        font-family: "iconfont";
        color: var(--color-light);
        top: 50%;
        transform: translateY(-50%) rotate(270deg);
        right: calc(48rem/16);
    }
}

.nav-dropdown__item.is-open .nav-dropdown__item__line-wrapper:before{
    color: var(--color-secondary);
}

.nav-dropdown-sub__item{
    font-size: calc(15rem/16);
    font-family: var(--font-default-bold);
}
.nav-dropdown-sub__item.active {
    color: var(--color-text-default-dark);
}
.nav-dropdown-sub__item+.nav-dropdown-sub__item{
    margin-top: calc(15rem/16);
}

@media screen and (max-width: 767px){
    .nav-dropdown.nav-dropdown--quickfinder{
        position: relative;
        opacity: 1;
        visibility: visible;
        margin-top: calc(30rem/16);
    }
    .nav-dropdown.nav-dropdown--quickfinder-content-visibility{
        position: relative;
        opacity: 1;
        content-visibility: hidden;
        margin-top: calc(30rem/16);
    }
}

@media screen and (max-width: 767px){
   .nav-dropdown__toggle{
       position: absolute;
       right: calc(20rem/16);
       top: calc(3rem/16);
       background-color: transparent;
       border: 1px solid;
       font-size: calc(9rem/16);
       width: calc(40rem/16);
       height: calc(40rem/16);
   }

    .nav-dropdown__item.active .nav-dropdown__toggle .icon{
        display: inline-block;
        transform: rotate(180deg);
    }
}