.search-slide-area {
    @media screen and (min-width: 768px) {
        display: block;
        position: relative;
    }
}

.search-slide-area__title {
    font-size: calc(26rem / 16);
    font-family: var(--font-headline);
    margin-bottom: calc(30rem / 16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(50rem / 16);
    }
}

.search-slide-area__body {
    padding: calc(35rem / 16) 0;
    position: relative;
    z-index: 1;
}

.search-slide-area__wrapper {
    position: relative;
    display: flex;
    align-items:center;
    @media screen and (max-width: 1199px) {
        height: 100%;
    }
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
    .search-slide-area__wrapper:after {
        width: 20.6875rem;
        height: 100%;
    }
}
@media screen and (min-width: 1200px) and (max-width: 1640px) {
    .search-slide-area__wrapper:after {
        width: 50%;
        height: 24.375rem;
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
}


@media screen and (min-width: 768px) {
    .search-slide-area__wrapper:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: calc(2rem / 16);
        width: calc(267rem / 16);
        height: calc(390rem / 16);
        background-image: url(/static/img/decorators/triangle-search.svg);
        background-size: cover;
        transform: translateX(100%);
        z-index: 1;
    }
}

/*
.search-slide-area__skew-holder:before {
    content: '';
    position: absolute;
    background-color: #1d1d1d;
    width: 800px;
    height: 800px;
    transform: rotate(37deg);
    left: -605px;
    top: -400px;
}*/
