.custom-file-upload input[type=file] {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    display: block;
    width: 100%;
}
.custom-file-upload__inner {
    position: relative;
    width: 100%;
    height: calc(100rem/16);
    padding: .75rem;
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    border: calc(2rem / 16) dashed #D8D8D8;
}
.custom-file-upload__inner--prefilled{
    min-height: calc(250rem /16);
}

.custom-file-upload__upload {
    cursor: pointer;
}

.custom-file-upload__body {
    display: block;
    position: relative;
    padding: 0.625rem;
}

.custom-file-upload__description {
    display: flex;
    align-items: center;
    color:  #D8D8D8;
}

.custom-file-upload__btn{
    color: var(--color-primary)!important;
    text-transform: none;
    font-family: var(--font-default-bold);
}

.custom-file-upload__icon{
    font-size: calc(22rem/ 16);
    margin-right: calc(10rem /16);
}