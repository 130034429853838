.datepicker-container{
  position: relative;
}
.datepicker-container:before {
  content: var(--icon-calendar);
  font-family: iconfont;
  color: var(--color-form-elements);
  speak: none;
  position: absolute;
  bottom: 50%;
  transform: translateY(50%);
  right: 0;
  font-size: calc(22rem/16);
  pointer-events: none;
}

.ui-datepicker {
  font-family: inherit;
  background: #ffffff;
  color: #222222;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.27);
  z-index: 1250 !important; /*override inline style*/
  font-size: 1rem;
  padding: .4em;
  
  @media screen and (min-width: 768px){
     width: calc(328rem/16);
  }

  @media screen and (max-width: 767px) {
    font-size: 1.2rem;
  }
}
.ui-datepicker table {
  font-size: 1em;
}
.ui-datepicker-title,
.ui-datepicker-calendar th{
  font-family: var(--font-default-bold);
  font-weight: normal;
}
.ui-datepicker td a,
.ui-datepicker td span {
  text-align: center;
}
.ui-datepicker table .ui-state-hover,
.ui-datepicker table .ui-state-active {
  background: var(--color-primary);
  color: #ffffff;
}
.ui-datepicker .ui-state-disabled {
  opacity: .4;
}
.ui-datepicker-next,
.ui-datepicker-prev {
  text-align: center;
  line-height: 1.8em;
  cursor: pointer;

  font-family: 'iconfont';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ui-datepicker-prev:before {
  content: var(--icon-arrow-left);
}
.ui-datepicker-next:after {
  content: var(--icon-arrow-right);
}
.ui-datepicker td a,
.ui-datepicker td span {
  padding: .4em;
}
.is-in-range .ui-state-default {
   background-color: var(--color-primary-light);
    color:#fff;
}
.is-arrival .ui-state-default, .is-departure .ui-state-default {
    background-color: var(--color-primary);
    color:#fff;
}