.fixed-info-message {
    position: fixed;
    margin-bottom:0;
    bottom: calc(20rem / 16);
    right: calc(20rem / 16);
    background-color: #fff;
    padding: calc(10rem / 16);
    color: #000;
    font-size: calc(14rem / 16);
    align-items: center;
    display: flex;
    box-shadow: 0 1px 9px 0 rgba(0, 0, 0, 0.4);
    border: 2px solid var(--color-info);
    padding-right: calc(35rem/16);
    text-align:left;
    z-index: 999;
    @media screen and (max-width: 767px) {
        bottom: calc(50rem/16);
        font-size: calc(12rem/16);
        padding: calc(10rem/16) calc(35rem/16) calc(10rem/16) calc(10rem/16);
    }
}
.fixed-info-message__close {
    position: absolute;
    right:0;
    top:0;
    height: 100%;
    border: none;
    background-color: var(--color-info);
    color:#fff;
    font-size: calc(10rem/16);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: calc(8rem/16);
}
.fixed-info-message__icon {
    color: var(--color-info);
}