.horizontal-teaser__body{
    padding: calc(20rem/16) 0;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 768px){
        height: 100%;
        padding: 0 calc(10rem/16) calc(25rem/16) calc(38rem/16);
    }
}

.horizontal-teaser__title{
    font-family: var(--font-headline);
    line-height: calc(22/18);
    text-transform: uppercase;
}

.horizontal-teaser__text{
    line-height: calc(20/16);
    
    @media screen and (min-width: 768px) and (max-width: 1200px){
       font-size: calc(15rem/16);
    }
}

.horizontal-teaser__img{
    box-shadow: 0 50px 60px 0 rgba(0,0,0,0.2);
}

@media screen and (min-width: 768px) {
    .horizontal-teaser__media {
        transition: transform 0.4s cubic-bezier(0.7, 0, 0.4, 1);
    }
    .horizontal-teaser:hover .horizontal-teaser__media {
        transform: scale(1.1) translateX(-50%);
    }
    .horizontal-teaser:hover .horizontal-teaser__title {
        color: var(--color-primary);
    }
}