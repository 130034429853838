.widget--bg-transparent {
    background-color: transparent !important;
}
.widget-wrapper{
    padding: calc(16rem/16) 0;
    @media screen and (max-width: 767px){
        overflow-x: hidden;
        overflow-y: auto;
    }
    @media screen and (min-width: 1200px){
        padding: calc(40rem/16) calc(50rem/16);
    }

    @media screen and (min-width: 1500px){
        padding: calc(125rem/16) calc(150rem/16);
    }
}

.widget-wrapper--card-teaser{
    @media screen and (max-width: 767px){
        padding-bottom: calc(70rem/16);
    }
}

.widget-wrapper--weather{
    padding: calc(16rem/16) 0 0;
    overflow-x: hidden;

    @media screen and (min-width: 1500px){
        padding: calc(125rem/16) calc(150rem/16);
    }
}

.widget-wrapper--small{
    padding: calc(16rem/16);
    overflow-x: hidden;

    @media screen and (min-width: 1500px){
        padding: calc(32rem/16) calc(32rem/16);
    }
}