.step-form {
    position: relative;
}
.step-form__legend-item {
    height: calc(10rem/16);
    width: calc(10rem/16);
    display: flex;
    justify-content: center;
    align-items:center;
    background-color: #fff;
    border-radius: 50%;
    transition: background-color 0.3s ease;
}
.step-form__legend-item.is-current {
    background-color: var(--color-primary);
}

.step-form__legend-item + .step-form__legend-item {
    margin-left: calc(20rem / 16);
}

.step-form__legend {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: absolute;
    top: calc(24rem /16);
    right: calc(24rem /16);
}

.step-form__steps-wrapper {
    padding: 3rem calc(20rem /16);
    @media screen and (min-width: 993px){
        padding: 3rem calc(136rem /16);
    }
}

.step-form__step.is-current {
    display: block;
}

.step-form__step {
    display: none;
}