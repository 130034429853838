.hero__claim-wrapper {
    position: absolute;
    left:0;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    @media screen and (max-width: 767px) {
        top: 30%;
    }
}
.hero__claim__sub-title {
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    font-size: calc(26rem/16);
    line-height: calc(32/26);
    color: #fff;
    @media screen and (max-width: 767px) {
        text-align: center;
        text-shadow: 0 2px 30px rgba(0,0,0,0.5);
    }
}
.hero__claim__title {
    font-size: calc(90rem/16);
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    display: inline-block;
}
.hero__claim svg {
    filter: drop-shadow(2px 2px 30px rgba(0,0,0,0.5));
}
.hero__bg-portal-switch {
    background-size: cover;
    background-attachment: scroll;
}
.hero__portal-switch path, .hero__portal-switch polygon, .hero__portal-switch rect  {
    stroke:#fff;
    stroke-width:4px;
    fill: transparent;
}
.hero__bg-portal-switch, .hero__live-infos__portal-switch {
    opacity: 0;
    transform: scale(1);
    transition: opacity 1.1s cubic-bezier(0.7, 0.2, 0.6, 1), transform 40s ease-in-out;
}
@media screen and (min-width: 768px) {
    .hero__portal-switch path, .hero__portal-switch polygon, .hero__portal-switch rect  {
        stroke-dasharray: 1200;
        stroke-dashoffset: 1200;
        stroke-width:1px;
        fill: transparent;
        stroke-linecap: round;
        fill:transparent;
        fill-rule:nonzero;
        stroke:#fff;
        stroke-linejoin:miter;
        stroke-miterlimit:10;
    }
    .hero__claim__sub-title {
        opacity: 0;
        transform: translateY(-20px);
        transition: opacity 0.4s ease, tranform 0.4s ease;
    }
    .is-in-viewport .hero__claim__sub-title {
        opacity:1;
        transform: translateY(0px);
    }
    .is-in-viewport .hero__portal-switch#mountains path, .is-in-viewport .hero__portal-switch#mountains polygon, .is-in-viewport .hero__portal-switch#mountains rect  {
        animation: switch 1.2s ease-in-out forwards 0.3s;
    }
    .is-in-viewport .hero__portal-switch.active path, .is-in-viewport .hero__portal-switch.active rect, .is-in-viewport .hero__portal-switch.active polygon{
        fill: #fff !important;
        transition: all 1.2s ease 1.1s;
    }
    .is-in-viewport .hero__portal-switch#lakes path, .is-in-viewport .hero__portal-switch#lakes polygon, .is-in-viewport .hero__portal-switch#lakes rect  {
        animation: switch 1.2s ease-in-out forwards 0.5s;
    }
    .hero__portal-switch path, .hero__portal-switch rect, .hero__portal-switch polygon, .hero__portal-switch{
        transition: all 1.2s ease;
    }
    .hero__portal-switch:hover path, .hero__portal-switch:hover rect, .hero__portal-switch:hover polygon{
        fill: #fff !important;
        transition: all 1.2s ease;
    }
}
@media screen and (max-width: 767px) {
    .hero__portal-switch.active path, .hero__portal-switch.active rect, .hero__portal-switch.active polygon{
        fill: #fff !important;
    }
}
.hero__bg-portal-switch.active, .hero__live-infos__portal-switch.active {
    opacity: 1;
    pointer-events: initial;
    fill:#fff;
    transition: opacity 1.1s cubic-bezier(0.7, 0.2, 0.6, 1), transform 8s ease-in-out;
}
.hero__portal-switch {
    cursor: pointer;
    position: relative;
    z-index: 9;
    pointer-events: bounding-box;
}
@keyframes switch {
    0% {
        stroke-dashoffset: 1200
    }
    33% {
        stroke-width:1;
    }
    90% {
        stroke-dashoffset: 0;
    }
    100% {
        stroke-dashoffset: 0;
        stroke-width:3;
    }
}

.hero__live-infos__portal-switch {
    position: absolute;
    pointer-events:none;
    top:0;
}