.drag-indicator {
    position: absolute;
    top: 0;
    left: 0;
    height: calc(75rem / 16 + 2.5vw);
    width: calc(75rem / 16 + 2.5vw);
    pointer-events: none;
}
@media (hover: none) and (pointer: coarse) {
    .drag-indicator {
        display: none;
    }
}
.drag-indicator__icon {
    font-size: calc(35rem/16);
    color: var(--color-white);
    display: flex;
    justify-content: center;
    align-items: center;
}
.drag-indicator__element {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-origin: center center;
    transform: translate(-50%, -50%) scale(.5);
    opacity: 0;
    transition: transform 120ms cubic-bezier(0, 0.47, 1, 1.35), opacity 120ms;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    text-align: center;
    border-radius: 50%;
    background-color: var(--color-dark);
    line-height: 1.2;
    font-size: calc(14rem / 16);
    pointer-events: none;

    display: none;
    @media screen and (min-width: 768px) {
        display: flex;
    }
    @media screen and (min-width: 1200px) {
        font-size: 1rem;
    }
    @media screen and (min-width: 1400px) {
        font-size: calc(18rem / 16);
    }
}
.drag-indicator.is-following .drag-indicator__element {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
}
.drag-indicator.is-grabbed .drag-indicator__element {
    transform: translate(-50%, -50%) scale(.7);
}