.weather-forecast__forecast-wrapper {
    height: 100%;
    background: linear-gradient(180deg, var(--color-default-bg) 0%, var(--color-dark) 100%);
    @media screen and (max-width: 767px) {
        height: auto;
    }
}
.weather-forecast__main-item {
    background-color: var(--color-dark);
    padding: calc(85rem/16) calc(60rem/16) calc(60rem/16);
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 1499px) {
        padding: calc(20rem/16) calc(10rem/16);
    }
    @media screen and (max-width: 767px) {
        height: auto;
    }
}
.weather-forecast__main-item__title {
    font-family: var(--font-default-bold);
    line-height:1;
    text-transform:uppercase;
    margin-bottom: 0;
    @media screen and (max-width: 1499px) {
        font-size: calc(22rem/16);
    }
}
.weather-forecast__main-item__icon {
    font-size: calc(120rem/16);
    display: block;
    margin: calc(20rem/16) 0;
    @media screen and (max-width: 1499px) {
        font-size: calc(60rem/16);
    }
}
.weather-forecast__main-item__value {
    font-size: calc(40rem/16);
    line-height: calc(49/40);
    font-family: var(--font-default-bold);
    @media screen and (max-width: 1499px) {
        font-size: calc(28rem/16);
    }
}
.weather-forecast__main-item__divider {
    height: calc(1rem/16);
    width: 80%;
    display: block;
    opacity: 0.4;
    margin: calc(40rem/16) auto;
    background-color: var(--color-light);
    @media screen and (max-width: 767px) {
        margin: calc(20rem/16) auto;
    }
}
.weather-forecast__main-item__value-label {
    text-transform: uppercase;
    color: var(--color-light);
    font-size: calc(16rem/16);
    @media screen and (max-width: 1499px) {
        font-size: calc(12rem/16);
    }
}
.weather-forecast__item {
    height: 100%;
    padding: calc(85rem/16) calc(60rem/16) calc(60rem/16);
    text-align: center;
    @media screen and (max-width: 1499px) {
        padding: calc(20rem/16) calc(10rem/16);
    }
    @media screen and (max-width: 767px) {
        padding: calc(7rem/16);
    }
}
.weather-forecast__item:after {
    content:'';
    position: absolute;
    right:0;
    width: calc(1rem/16);
    background-color:var(--color-secondary);
    height: 80%;
    top: 50%;
    transform: translateY(-50%);
}
.weather-forecast__item__title {
    text-transform: uppercase;
    font-size: calc(12rem/16);
    margin-bottom: 0;
    line-height: 1;
    font-family: var(--font-default-bold);
}
.weather-forecast__item__icon {
    font-size: calc(70rem/16);
    display: block;
    margin: calc(20rem/16) 0;
    @media screen and (max-width: 767px) {
        font-size: calc(32rem/16);
    }
}
.weather-forecast__item__value {
    font-size: calc(20rem/16);
    line-height: calc(24/20);
    font-family: var(--font-default-bold);
}
.weather-forecast__item__value-label {
    text-transform: uppercase;
    color: var(--color-light);
    font-size: calc(12rem/16);
}
.weather-forecast__item__header {
    max-height: 40%;
}
.weather-forecast__item__date {
    font-size: calc(12rem/16);
}
.weather-forecast__canvas {
    position: absolute;
    top: 40%;
    left: calc(100%/8 - calc(9rem/16));
    width: calc(100% - 100%/4 + calc(18rem/16));
    @media screen and (max-width:767px) {
        position: relative;
        top: auto;
        left: calc(100%/8 - calc(9rem/16));
        width: calc(100% - 100%/4 + calc(18rem/16));
    }
}
.weather-forecast__legend {
    text-transform: uppercase;
    font-size: calc(20rem/16);
    font-family: var(--font-default-bold);
    bottom: calc(40rem/16);
    position: absolute;
    left:0;
    right:0;
    @media screen and (max-width: 767px) {
        bottom: calc(5rem/16);
        font-size: calc(14rem/16);
    }
}
.weather-forecast__canvas-wrapper {
    height: calc(400rem/16);
    @media screen and (max-width: 767px) {
        height: calc(200rem/16);
    }
}