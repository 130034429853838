.decorative-img-text-teaser-area {
    position: relative;
    @media screen and (max-width: 767px) {
        padding: calc(70rem/16) 0;
    }
    @media screen and (min-width: 768px) {
        padding:  calc(150rem/16) 0;
    }
}
.decorative-img-text-teaser-area--has-bg-claim {
    @media screen and (max-width: 767px) {
        background-color: var(--color-dark);
    }
    @media screen and (min-width: 768px) {
        background-image: url("/static/img/claim/bg-claim.svg");
        background-size: cover;
    }
}
.decorative-img-text-teaser-area--has-bg-claim--light {
    @media screen and (max-width: 767px) {
        background-color: var(--color-dark);
    }
    @media screen and (min-width: 768px) {
        background-image: url("/static/img/claim/bg-claim-white.svg");
        background-size: cover;
    }
}
.decorative-img-text-teaser-area:before {
    content:'';
    position: absolute;
    left:0;
    width: 100%;
    bottom: 0;
    height: 40%;
    background: linear-gradient(180deg,rgba(61,61,61,0),var(--color-default-bg));
}
.decorative-img-text-teaser-area:after {
    content:'';
    position: absolute;
    left:0;
    width: 100%;
    top: 0;
    height: 40%;
    z-index: 0;
    background: linear-gradient(to bottom, var(--color-default-bg) 0%,rgba(61,61,61,0) 100%);
}
.decorative-img-text-teaser-area__inner {
    z-index: 1;
    position: relative;
}
.decorative-img-text-teaser__embed {
    padding-top: calc(512 / 373 * 100%);
    box-shadow: 10px 10px 88px -12px rgba(0,0,0,0.75);
}
.decorative-img-text-teaser {
    cursor:pointer;
    @media screen and (max-width: 767px) {
        padding-left: calc(var(--gutter) / 2);
    }
}
.decorative-img-text-teaser__body {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    z-index: 3;
    @media screen and (max-width: 767px) {
        margin-top: calc(10rem/16);
    }
    @media screen and (min-width: 768px) {
        height: 100%;
    }
}
.decorative-img-text-teaser__title {
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    margin-bottom: calc(20rem/16);
    @media screen and (max-width: 767px) {
        margin-top: calc(10rem/16);
        margin-bottom: calc(5rem/16);
        font-size: calc(18rem/16);
    }
    
    @media screen and (min-width: 768px) and (max-width: 1100px){
       font-size: calc(17rem/16);
    }
}
.decorative-img-text-teaser__content {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    margin-bottom: calc(20rem/16);
    @media screen and (max-width: 767px) {
        margin-top: calc(10rem/16);
    }
}
.decorative-img-text-teaser__decorative-title {
    font-size: calc(52rem/16);
    line-height: calc(83/70);
    margin-bottom: calc(35rem/16);
    font-family: var(--font-decorative);
    text-shadow: 0 calc(2rem/16) calc(30rem/16) rgba(0,0,0,0.3);
    z-index: 2;
    transition: transform 0.3s ease, color 0.4s ease;
    @media screen and (max-width: 767px) {
        transform: rotate(-10deg);
        bottom:0;
        position: absolute;
        left:0;
        font-size: calc(36rem/16);
        padding-left: calc(10rem/16);
        margin-bottom: calc(15rem/16);
        text-align: left;
    }
    @media screen and (min-width: 768px) and (max-width: 1199px){
        font-size: calc(36rem/16);
    }
    @media screen and (min-width: 768px) {
        transform: rotate(-10deg) translateX(-50%) translateY(-10%);
    }

    @media screen and (min-width: 1500px){
        font-size: calc(70rem/16);
    }
}
.decorative-img-text-teaser__media-wrapper:before {
    content:'';
    position: absolute;
    background: linear-gradient(90deg, rgba(61,61,61,0) 0%, var(--color-default-bg) 100%);
    top:0;
    left:0;
    width: 100%;
    height:100%;
    z-index: 1;
    opacity:0.6;
    transition: opacity 0.3s ease;
    @media screen and (max-width: 767px) {
        background: linear-gradient(270deg, rgba(61,61,61,0) 0%, var(--color-default-bg) 100%);
    }
}
.decorative-img-text-teaser__media-wrapper {
    position: relative;
    z-index: -1;
}
.decorative-img-text-teaser__slider {
    @media screen and (max-width: 767px) {
        overflow: hidden;
        margin-right: calc(var(--gutter) / -2);
        margin-left: calc(var(--gutter) / -2);
    }
}
.decorative-img-text-teaser__slider .slick-list {
    overflow: visible !important;
}
@media screen and (min-width: 768px) {
    .decorative-img-text-teaser--img-left {
        margin-top: calc(60rem/16);
    }
    .decorative-img-text-teaser--img-right .decorative-img-text-teaser__body {
        align-items: flex-end;
        text-align: right;
    }
    .decorative-img-text-teaser--img-right .decorative-img-text-teaser__decorative-title {
        transform: rotate(-10deg) translateX(35%);
        width: 150%;
    }
    .decorative-img-text-teaser:hover .decorative-img-text-teaser__decorative-title {
        transform: rotate(-12deg) translateX(-55%) translateY(-10%);
    }
    .decorative-img-text-teaser.decorative-img-text-teaser--img-right:hover .decorative-img-text-teaser__decorative-title {
        transform: rotate(-8deg) translateX(40%);
    }
    .decorative-img-text-teaser--img-right .decorative-img-text-teaser__media-wrapper:before {
        background: linear-gradient(270deg, rgba(61,61,61,0) 0%, var(--color-default-bg) 100%);
    }
    .decorative-img-text-teaser:hover .decorative-img-text-teaser__media-wrapper:before {
        opacity: 0.3;
    }
    .decorative-img-text-teaser__media {
        transition: 0.5s ease;
        backface-visibility: hidden;
    }
    .decorative-img-text-teaser__media-wrapper {
        transition: 0.3s ease;
        backface-visibility: hidden;
    }
    .decorative-img-text-teaser:hover .decorative-img-text-teaser__media-wrapper {
        transform: scale(1.04);
    }
    .decorative-img-text-teaser:hover .decorative-img-text-teaser__media {
        transform: scale(1.1);
    }
    .decorative-img-text-teaser + .decorative-img-text-teaser {
        margin-top: calc(45rem/16);
    }
    .decorative-img-text-teaser--img-left + .decorative-img-text-teaser--img-left {
        margin-top: calc(-40rem/16);
    }
}

.decorative-img-text-teaser .btn{
    @media screen and (min-width: 768px) and (max-width: 1100px){
       font-size: calc(12rem/16);
    }
}

.decorative-img-text-teaser--img-right .decorative-img-text-teaser__decorative-title{
    @media screen and (min-width: 768px) and (max-width: 1100px){
        margin-bottom: calc(15rem/16);
    }
}