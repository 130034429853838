/* ordered list of all media queries (for mqpacker)*/
@media screen and (max-width: 349px) { }
@media screen and (min-width: 350px) { }
@media screen and (max-width: 767px) { }
@media screen and (min-width: 768px) { }
@media screen and (min-width: 1100px) { }
@media screen and (min-width: 1150px) { }
@media screen and (max-width: 1199px) { }
@media screen and (min-width: 1200px) { }
@media screen and (max-width: 1399px) { }
@media screen and (min-width: 1400px) { }
@media screen and (max-width: 1499px) { }
@media screen and (min-width: 1500px) { }
@media screen and (min-width: 1650px) { }