.vertical-gutter--1 {
    margin-top: -.25rem;
}
.vertical-gutter--1 > .vertical-gutter__item {
    margin-top: .25rem;
}
.vertical-gutter--2 {
    margin-top: -.5rem;
}
.vertical-gutter--2 > .vertical-gutter__item {
    margin-top: .5rem;
}
.vertical-gutter--3 {
    margin-top: -1rem;
}
.vertical-gutter--3 > .vertical-gutter__item {
    margin-top: 1rem;
}
.vertical-gutter--4 {
    margin-top: -1.5rem;
}
.vertical-gutter--4 > .vertical-gutter__item {
    margin-top: 1.5rem;
}
.vertical-gutter--5 {
    margin-top: -3rem;
}
.vertical-gutter--5 > .vertical-gutter__item {
    margin-top: 3rem;
}
.vertical-gutter--6 {
    margin-top: -4rem;
}
.vertical-gutter--6 > .vertical-gutter__item {
    margin-top: 4rem;
}
.vertical-gutter--default {
    margin-top: calc(var(--gutter) * -1);
}
.vertical-gutter--default > .vertical-gutter__item {
    margin-top: calc(var(--gutter));
}