.pauschal-teaser{
    position: relative;
    color: #fff;
    box-shadow: 0 50px 60px 0 rgba(0,0,0,0.2);
    transform: translateZ(0);
}

@media screen and (min-width: 768px) {
    .pauschal-teaser__img {
        transition: transform 0.6s ease;
        transform: translateZ(0);
    }
    .pauschal-teaser:hover .pauschal-teaser__img {
        transform: scale(1.1);
    }
    .pauschal-teaser:hover:before {
        opacity:0.7;
    }
}
.pauschal-teaser:before{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    transition: opacity 0.6s ease;
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(61,61,61,0.6) 100%);
}

.pauschal-teaser__content{
    position: absolute;
    bottom: calc(-21rem/16);
    left: calc(15rem/16);
    right: calc(15rem/16);
    z-index: 1;

    @media screen and (min-width: 768px){
        left: calc(10rem/16);
        right: calc(10rem/16);
        bottom: calc(-17rem/16);
    }
    @media screen and (min-width: 1100px){
        left: calc(15rem/16);
        right: calc(15rem/16);
        bottom: calc(-26rem/16);
    }
    @media screen and (min-width: 1400px){
        left: calc(35rem/16);
        right: 20%;
    }
}

.pauschal-teaser--profile .pauschal-teaser__content {
    bottom: calc(15rem/16);

    @media screen and (min-width: 768px){
        bottom: calc(10rem/16);
    }
    @media screen and (min-width: 1100px){
        bottom: calc(15rem/16);
    }
    @media screen and (min-width: 1400px){
        bottom: calc(40rem/16);
    }
}

.pauschal-teaser--profile .pauschal-teaser__bottom {
    margin-left: 0;
}

.pauschal-teaser__date{
    line-height: calc(32/26);
    text-shadow: 0 2px 4px rgba(0,0,0,0.5);

    @media screen and (min-width: 768px){
        font-size: calc(17rem/16);
    }

    @media screen and (min-width: 1200px){
        font-size: calc(18rem/16);
    }

    @media screen and (min-width: 1400px) {
        font-size: responsive 20px 26px;
        font-range: 1400px 1920px;
    }
}

.pauschal-teaser__title{
    font-family: var(--font-default-bold);
    line-height: calc(43/35);
    text-transform: uppercase;
    font-size: calc(14rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(19rem/16);
    }

    @media screen and (min-width: 1200px){
       font-size: calc(22rem/16);
    }

    @media screen and (min-width: 1400px) {
        font-size: responsive 27px 35px;
        font-range: 1400px 1920px;
    }
}

.pauschal-teaser__text{
    font-family: var(--font-default-bold);
    display: flex;
    align-items: flex-end;
    font-size: calc(13rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(13rem/16);
    }

    @media screen and (min-width: 1200px){
        font-size: calc(14rem/16);
    }

    @media screen and (min-width: 1400px){
       font-size: calc(16rem/16);
        min-height: calc(72rem/16);
    }
    @media screen and (max-width: 768px){
        display: none;
    }
}

.pauschal-teaser__bottom{
    margin-left: calc(15rem/16);

    @media screen and (min-width: 768px){
        margin-left: calc(20rem/16);
    }
    @media screen and (min-width: 1400px){
        margin-left: 14%;
    }
}

.pauschal-teaser__bottom .btn{
    @media screen and (min-width: 768px) and (max-width: 1100px){
       padding: calc(8rem/16) calc(20rem/16);
    }
    
    @media screen and (max-width: 767px){
       padding: calc(7rem/16) calc(20rem/16);
        font-size: calc(13rem/16);
    }
}

/* pauschal-teaser-small */

.pauschal-teaser--small.pauschal-teaser{
    box-shadow: none;
}

.pauschal-teaser--small .pauschal-teaser__title{
    font-size: calc(14rem/16);
    line-height: calc(18/14);
}

.pauschal-teaser--small .pauschal-teaser__text{
    display: none;
}