.triangle-img__wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;

    @media screen and (min-width: 768px) {
        padding-right: calc(275rem/16);
    }

}
.triangle-img__decorator {
    max-width: calc(770rem/16);
    height: auto;
    width: 100%;
}
.triangle-img__media-wrapper {
    border-radius: 50%;
    overflow: hidden;
    max-width: calc(244rem/16);
    z-index: 10;

    @media screen and (max-width: 1199px) {
        max-width: calc(150rem/16);
    }
    @media screen and (max-width: 767px) {
        max-width: calc(100rem/16);
    }
}

.triangle-img__wrapper--profile .triangle-img__media-wrapper {
    max-width: calc(200rem/16);
    @media screen and (max-width: 1199px) {
        max-width: calc(150rem/16);
    }
    @media screen and (max-width: 767px) {
        max-width: calc(100rem/16);
    }
}

.triangle-img__media-wrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}