:root {
    --navbar-info-height: calc(72rem/16);
    --navbar-info-height--xs: calc(40rem/16);
}
.navbar-info{
    font-size: calc(11rem/16);
    height: var(--navbar-info-height--xs);
    display:flex;
    justify-content: center;
    align-items:center;
    padding-left: calc(5rem/16);

    @media screen and (min-width: 768px){
        height: var(--navbar-info-height);
        font-size: calc(20rem/16);
    }

    @media screen and (min-width: 1200px){
        font-size: calc(26rem/16);
    }
}

.navbar-info .btn,
.nav-info-slider .btn, {
    @media screen and (max-width: 767px){
        font-size: calc(10rem/16);
        padding: calc(10rem/16) calc(5rem/16);
        white-space: nowrap;
    }
}
.navbar-info__close  {
    position: absolute;
    top:0;
    right:0;
    padding: calc(5rem/16);
    @media screen and (max-width: 767px) {
        position: relative;
    }
}

.nav-info-slider {
    position: relative;
    font-size: calc(11rem/16);
    height: var(--navbar-info-height--xs);
    padding-left: calc(5rem/16);

    @media screen and (min-width: 768px){
        height: var(--navbar-info-height);
        font-size: calc(16rem/16);
    }

    @media screen and (min-width: 1200px){
        font-size: calc(22rem/16);
    }
}
.nav-info-slider .nav-info-slider__slide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: var(--navbar-info-height--xs);

    @media screen and (min-width: 768px){
        height: var(--navbar-info-height);
    }
}

.nav-info-slider .slick-dots {
    display: none;
    visibility: hidden;
    @media screen and (min-width: 768px){
        display: block;
        visibility: visible;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }
}

.nav-info-slider .slick-dots li {
    position: relative;
    display: inline-block;
    width: 1.5rem;
    height: 1rem;
    margin: 0 0.5rem;
    padding: 0;
    cursor: pointer;
}

.nav-info-slider .slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 1.5rem;
    height:  1rem;
    padding: 0.25rem;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.nav-info-slider .slick-dots li.slick-active button:before {
    opacity:1;
}

.nav-info-slider .slick-dots li button:before {
    content: '';
    line-height: 10px;
    position: absolute;
    bottom: 0.5rem;
    left: 0;
    width: 1.5rem;
    height: 0.125rem;
    text-align: center;
    opacity: .5;
    background-color: #fff;
}

.nav-info-slider .navbar-info__close {
    @media screen and (max-width: 767px){
        margin-left: auto;
    }
}

.nav-info-slider .nav-info-slider__link {
    padding: 0.5rem;
    font-size: 12px;
    @media screen and (min-width: 768px){
        padding: 0.5rem 2rem;
        font-size: 14px;
    }
}