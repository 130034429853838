#region-map {
    @media screen and (max-width: 767px) {
        max-height: calc(300rem/16);
    }
}
.region-map__item:hover .region-map__item-bg {
    fill: var(--color-primary) !important;
}
.region-map__item {
    cursor: pointer;
}
.region-list {
    @media screen and (max-width: 767px) {
        padding-left: calc(30rem/16);
    }
}
.region-list__item {
    cursor: pointer;
    font-size: calc(16rem / 16);
    letter-spacing: calc(1rem / 16);
    line-height: calc(25 / 16);
    margin-bottom: calc(15rem / 16);
    transition: color 0.2s ease;
}
.region-list__item:hover {
    color: var(--color-primary);
}
.region-map__item-bg {
    transition: all 0.3s ease;
}
.region-map--hover .region-map__item-bg {
    transition: all 0.3s ease;
    fill: var(--color-primary) !important;
}
.region-list__link.active, .region-list__link:hover {
    color: var(--color-primary);
    font-family: var(--font-default-bold);
}
.region-map__map svg {
    height: calc(300rem/16);
    width: 100%;
    @media screen and (max-width: 767px) {
        padding-top: calc(20rem/16);
        padding-bottom: calc(20rem/16);
        height: auto;
    }
}
#region-map {
    width: 100%;
    height:100%;
}
