.thumb-slider__embed {
    padding-top: calc(659 / 1057 * 100%);
}
.thumb-slider__thumb-item {
    position: relative;
    @media screen and (min-width: 768px) {
        width: calc(80rem/16) !important;
    }
}
.thumb-slider__thumbs {
    display: flex;
    margin-top: calc(-40rem/16);
}
.thumb-slider__thumbs .slick-slide {
    margin-left: calc(15rem/16);
    @media screen and (max-width: 767px) {
        margin-left: calc(5rem/16);
    }
}
.thumb-slider__thumbs .slick-track {
    margin-right:0;
}
.thumb-slider__main {
    box-shadow: 0 calc(50rem/16) calc(60rem/16) 0 rgba(0,0,0,0.2);
}
.thumb-slider__arrow  {
    background: none;
    border: none;
    color: var(--color-text-default);
    display: flex;
    font-size: calc(25rem/16);
    align-items: center;
    width: calc(50rem/16);
    padding: calc(10rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(18rem/16);
    }
}
.thumb-slider__thumb-item:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    background-color: var(--color-secondary);
    z-index: 2;
    opacity: 0.6;
    top:0;
    left:0;
    transition: opacity 0.2s ease;
}
.slick-slide.slick-current .thumb-slider__thumb-item:before {
    opacity: 0;
}