.recipe-teaser {
    position: relative;
    cursor:pointer;
}
.recipe-teaser:before {
    opacity: 0.5;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    content:'';
    transition: opacity 0.4s cubic-bezier(0.7, 0, 0.3, 1);
    background: linear-gradient(180deg, rgba(61,61,61,0) 0%, #3D3D3D 100%);
}
.recipe-teaser__content {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: calc(30rem/16) calc(30rem/16) calc(35rem/16);
    @media screen and (max-width: 767px) {
        padding: calc(20rem/16);
    }
}
.recipe-teaser__icon {
    font-size: calc(50rem/16);
    position: absolute;
    top: 0;
    right: 0;
    text-shadow: 0 calc(2rem/16) calc(4rem/16) rgba(0,0,0,0.5);
    padding: calc(20rem/16);
}
.recipe-teaser__title {
    font-size: calc(24rem/16);
    font-family: var(--font-default-bold);
    line-height: 1;
    text-transform: uppercase;
    text-shadow: 0 calc(2rem/16) calc(4rem/16) rgba(0,0,0,0.5);
    margin-bottom:0;
    @media screen and (min-width: 768px) {
        max-width: 80%;
    }
}
.recipe-teaser__sub-title {
    text-transform: uppercase;
    font-size: calc(16rem/16);
    margin-top: calc(5rem/16);
    margin-bottom: calc(25rem/16);
    text-shadow: 0 calc(2rem/16) calc(4rem/16) rgba(0,0,0,0.5);
}
@media screen and (min-width: 768px) {
    .recipe-teaser__media {
        transition: transform 0.4s cubic-bezier(0.7, 0, 0.4, 1);
    }
    .recipe-teaser:hover .recipe-teaser__media {
        transform: scale(1.1);
    }
    .recipe-teaser:hover:before {
        opacity:0.3;
    }
}