.hero {
    width: 100%;
    height: 100vh;
    display: flex;
    margin: 0 auto;
    justify-content: flex-end;
    position:relative;
    flex-direction: column;
    @media screen and (max-width: 767px) {
        align-items: flex-end;
        padding-bottom: calc(105rem/16);
        overflow:visible;
        height: 80vh;
        max-height: calc(550rem/16);
    }
}
.hero--full-height {
    height: 86vh;
    max-height: calc(800rem/16);
    @media screen and (max-width: 767px) {
        height: 80vh;
    }
}
.hero-portal.hero--full-height {
    height: 106vh;
    max-height: calc(1000rem/16);
    @media screen and (max-width: 767px) {
        height: 80vh;
    }
}
.hero__bg-wrapper {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    padding-top: var(--main-nav-height);
    left:0;
    z-index: -1;
    @media screen and (max-width: 767px) {
        padding-top: var(--main-nav-height--xs);
        height: calc(80vh + 5rem);
    }
}
.hero:before {
    content:'';
    position: absolute;
    bottom:0;
    left:0;
    width: 100%;
    height:60%;
    z-index: 1;
    pointer-events: none;
    background: linear-gradient(180deg, rgba(61,61,61,0) 0%, var(--color-default-bg) 100%);
}
.hero__bg {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}
.hero__bg-video {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow:hidden;
    position:absolute;
}
.hero__bg-video__video, .hero__bg-video video, .hero__bg-video .vjs-poster{
    min-width: 100vw!important;
    min-height: 100vh;
    left: 0;
    margin: 0 auto;
    right: 0;
    position: absolute;
    height: auto;
    width: auto;
    @media screen and (max-width: 767px) {
        min-height: calc(100vh - var(--main-nav-height--xs));
        left: 50%;
        transform: translateX(-50%);
    }
}
.hero__bg-video .vjs-poster{
    min-width: 100vw!important;
    min-height: 100vh !important;
    width: auto !important;
}
.hero__bg-video .vjs-poster {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: var(--color-secondary);
}
.hero__bg-video .hero__bg-video__video:before {
    content:'';
    position: absolute;
    top:0;
    left:0;
    z-index: 9;
    width: 100%;
    height:100%;
    background-color: rgba(0,0,0,0.3);
    transition: background-color 0.4s ease;
}
.hero__bg-video .vjs-v7.hero__bg-video__video:before {
    background-color: rgba(0,0,0,0.7);
}
.hero__bg-video .vjs-has-started.hero__bg-video__video:before {
    background-color: rgba(0,0,0,0);
}
.hero__bg-video .vjs-big-play-button, .hero__bg-video .vjs-control-bar {
    display: none !important;
}
.hero--full-height .hero__content {
    width: 100%;
    transition: transform 0.8s ease, opacity 0.6s ease;
    transform: translateY(50px);
    opacity:0;
    z-index: 3;
    @media screen and (max-width: 767px) {
        opacity:1;
        transform: translateY(0px);
    }
}
.hero-portal .hero__content {
  width: 100%;
  z-index: 3;
}

@media screen and (min-width: 768px) {
    .hero-portal .hero__content {
        transition: transform 0.8s ease 1.5s, opacity 0.6s ease 1.5s;
        transform: translateY(50px);
        opacity:0;
    }
    .is-in-viewport.hero--full-height .hero__content {
        opacity:1;
        transform: translateY(0);
    }
}
.hero__title-wrapper {
    padding-bottom: calc(114rem/16);
    @media screen and (max-width: 767px) {
        padding-bottom: calc(30rem/16);
    }
}
.hero__title {
    text-transform: uppercase;
    line-height:1;
}
.hero-portal.hero--full-height .hero__title {
    font-size: calc(34rem/16);
    margin-bottom: 0;
    @media screen and (max-width: 767px) {
        font-size: calc(28rem/16);
        word-break: break-word;
    }
}
.hero--full-height .hero__title {
    font-size: calc(50rem/16);
    margin-bottom: calc(20rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(28rem/16);
        word-break: break-word;
    }
}
.hero-portal.hero--full-height .hero__title-wrapper {
    padding-bottom: calc(60rem / 16);
    @media screen and (max-width: 767px) {
        padding-bottom: calc(30rem/16);
    }
}
.hero--full-height .hero__title-wrapper {
    padding-bottom: calc(70rem/16);
    margin-top: calc(30rem/16);
    @media screen and (max-width: 767px) {
        margin-top: calc(10rem/16);
        padding-bottom: calc(20rem/16);
    }
}
.hero__sub-title {
    font-size: calc(16rem/16);
    line-height: calc(32/26);
    margin-top: calc(12rem/16);
    word-break: break-word;
    @media screen and (min-width: 768px) {
        font-size: responsive 16px 26px;
        font-range: 768px 1600px;
    }
}
.hero__sub-title--uppercase {
    text-transform: uppercase;
}
.hero--full-height .hero__sub-title {
    margin-top: 0;
}
.hero__additional-info__icon {
    width: calc(40rem/16);
    font-size: calc(24rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(20rem/16);
        width: calc(30rem/16);
    }
}
.hero__additional-infos {
    margin-top: calc(30rem/16);
    @media screen and (max-width: 767px) {
        margin-top: calc(15rem/16);
    }
}
.hero__additional-info {
    display: flex;
    align-items: flex-start;
    font-size: calc(20rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(16rem/16);
    } 
}
.hero__additional-info + .hero__additional-info {
    margin-top: calc(15rem/16);
    @media screen and (max-width: 767px) {
        margin-top: calc(5rem/16);
    }
}

.hero__icon{
    font-size: calc(35rem/16);
    vertical-align: calc(11rem/16);

    @media screen and (min-width: 1200px){
        font-size: calc(65rem/16);
        vertical-align: calc(6rem/16);
    }
}
.hero--full-height.hero--microsite .hero__title {
    font-size: calc(50rem / 16);
    text-shadow: 0 2px 4px rgba(0,0,0,0.5);
    @media screen and (max-width: 1199px) {
        font-size: calc(34rem / 16);
    }
    @media screen and (max-width: 767px) {
        font-size: calc(28rem / 16);
    }
}
.hero--microsite .hero__sub-title {
    font-size: calc(30rem/16);
    @media screen and (min-width: 768px) {
        font-size: responsive 30px 50px;
        font-range: 768px 1600px;
    }
}
.hero--full-height.hero--microsite .hero__icon {
    text-shadow: 0 2px 20px rgba(0,0,0,0.5);
    font-size: calc(200rem/16);
    @media screen and (max-width: 1199px) {
        font-size: calc(50rem/16);
    }
}
