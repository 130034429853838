.loading-spinner {
    margin-left: auto;
    margin-right: auto;
    width: auto;
    text-align: center;
}
.loading-spinner__item {
    width: calc(300rem/16);
    height: auto;
}
.loading-spinner__item path {
    stroke-dasharray: 450;
    stroke-dashoffset: 450;
    animation: loading-spinner-stroke 1.5s infinite ease-in-out;
}
@keyframes loading-spinner-stroke {
    0% {
        stroke-dashoffset: 450;
        fill: transparent;
    }
    100% {
        stroke-dashoffset: 0;
        fill:#fff;
    }
}
