.footer{
    background-color: var(--color-default-bg);
    @media screen and (min-width: 768px) and (max-width: 1200px){
       font-size: calc(14rem/16);
    }
    @media screen and (max-width: 767px) {
        padding-bottom: calc(30rem/16);
    }
}

.footer__top{
    padding: calc(30rem/16) 0;
    
    @media screen and (min-width: 768px){
       padding: calc(85rem/16) 0 calc(75rem/16);
    }
}

.footer__bottom{
    padding: calc(20rem/16) 0;
    border-top: calc(1rem/16) solid var(--color-text-default);
}

.footer__logo-elements img {
    max-width: calc(150rem/16) !important;
}

.footer__title{
    font-size: calc(17rem/16);
    text-transform: uppercase;
    color: var(--color-footer-headlines);
    font-family: var(--font-default-bold);

    @media screen and (min-width: 1200px){
        font-size: calc(20rem/16);
    }
}

.list-social{
    margin-top: calc(25rem/16);

    @media screen and (min-width: 768px){
        margin-top: calc(40rem/16);
    }
    
    @media screen and (max-width: 767px){
       margin-bottom: calc(10rem/16);
    }
}

.list-social .list-inline-item:not(:last-child){
    margin-right: calc(15rem/16);

    @media screen and (min-width: 768px) and (max-width: 1399px){
        margin-right: calc(8rem/16);
    }
}

.list-social__item{
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-default-bg);
    background-color: var(--color-text-default);
    opacity:0.8;

    width: calc(50rem/16);
    height: calc(50rem/16);
    font-size: calc(25rem/16);
    border-radius: 50%;

    @media screen and (min-width: 768px) and (max-width: 1399px){
        width: calc(42rem/16);
        height: calc(42rem/16);
        font-size: calc(18rem/16);
    }
}
.list-social__item:hover {

    color: var(--color-primary);
}

.footer__link-list{
    @media screen and (min-width: 768px){
       padding-left: 16%;
    }
    
    @media screen and (max-width: 767px){
       margin-top: calc(40rem/16);
    }
}
.footer__link-list>li{
    margin-bottom: calc(8rem/16);
}

.footer__logo{
    @media screen and (max-width: 767px){
       margin-top: calc(40rem/16);
    }
}