/* Text Helper */
.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


/* Text Color Helper */
.text-white { color: #ffffff; }
.text-color-default { color: var(--color-text-default); }
.text-underline { text-decoration: underline; }
.text-light-blue { color: var(--color-light-blue); }
.text-light {color: var(--color-light);}
/* Background Color Helper */

/* Additional */
.btn-no-styling {
    border: none;
    background: none;
    text-align: left;
    padding: 0;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    cursor: pointer;
}
.bg-default-color {
    background-color: var(--color-default-bg);
}

.increased-click-area {
    padding: calc(8rem/16);
    margin: calc(-8rem/16);
}
.isCursor { cursor: pointer; }
.pointer-events-none {pointer-events: none;}

.h-md-100{
    @media screen and (min-width: 768px){
       height: 100%;
    }
}

.mt-md--gutter {
    @media screen and (min-width: 768px) {
        margin-top: var(--gutter);
    }
}

.embed-responsive-cover{
    height: 100%;
}

.embed-responsive-cover .embed-responsive-item{
    width: auto;
    min-height: 100%;
    left: 50%;
    transform: translateX(-50%);
}


.fz20 { font-size: calc(20rem/16); }
.fz16 { font-size: calc(16rem/16); }
.fz12 { font-size: calc(12rem/16); }
.fz14 { font-size: calc(14rem/16); }


.fz-md-16 {
    font-size: calc(15rem/16);
    @media screen and (min-width: 768px) {
        font-size: calc(16rem/16);
    }
}

.display-contents {
    display: contents;
}

.fill-primary {
    fill: var(--color-primary);
}

.link__underline:hover {
    text-decoration: underline;
}

.border-bottom__white{
    border-bottom: calc(2rem /16) solid #fff;
}

.image-cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.z-index-1 {
    z-index: 1;
}