.press-text-teaser {
    border-radius: calc(10rem/16);
    box-shadow: 0 calc(40rem/16) calc(40rem/16) 0 rgba(0,0,0,0.2);
    overflow: hidden;
    background-color:#fff;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.press-text-teaser__body {
    color: var(--color-text-default-dark);
    padding: calc(30rem/16) calc(40rem/16);
    display: flex;
    flex-direction: column;
    flex: 1 auto;
    @media screen and (max-width: 767px) {
        padding: calc(20rem/16);
    }
}
.press-text-teaser__title {
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    font-size: calc(20rem/16);
    margin-bottom: calc(5rem/16);
    transition: color 0.2s ease;
}
.press-text-teaser__date {
    margin-bottom: calc(25rem/16);
    font-size: calc(16rem/16);
}
.press-text-teaser__content {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    margin-bottom: calc(12rem/16);
}
.press-text-teaser__download-link .icon {
    font-size: calc(20rem/16);
}
.press-text-teaser__download-link {
    font-family: var(--font-default-bold);
    display: flex;
}

@media screen and (min-width: 768px) {
    .press-text-teaser__media {
        transition: transform 0.5s cubic-bezier(0.5, 0, 0.7, 1);
    }
    .press-text-teaser:hover .press-text-teaser__title {
        color: var(--color-primary);
    }
    .press-text-teaser:hover .press-text-teaser__media {
        transform: scale(1.1);
    }
}